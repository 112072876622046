import { Decimal } from 'decimal.js';
import { Contract, Web3 } from 'web3';
import { rolldownAbi } from '../../../ContractAbis.json';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional, TransactionStore, TxType, transformToAsset } from '../../../../../';
import { RollupChainTx, TrackingTx, TrackingTxStatus } from '../../../../transaction';
import { RollupToken, RollupStashChain } from '../../../stash/RollupStash';
import { NATIVE_TOKEN_ADDRESS } from '../../../token/list/services/rollupTokensService';
import { Config } from 'wagmi';
import { Hex } from 'viem';

export type RollupDepositQueryParams = [
  string,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<boolean>,
];

export interface RollupDepositMutationParams {
  userAddress: QueryOptional<string>;
  token: RollupToken;
  amount: string;
  gasAmount: QueryOptional<string>;
  chain: QueryOptional<RollupStashChain>;
  trackingMeta: TrackingTx['trackingMeta'];
}

const web3 = new Web3();

export const getRollupDepositFee =
  (rolldownContract: QueryOptional<Contract<typeof rolldownAbi>>) =>
  async ({ queryKey }: QueryFunctionContext<RollupDepositQueryParams>) => {
    const [, tokenAddress, userAddress, amount, gasPrice] = queryKey;

    if (!tokenAddress || !amount || !userAddress || !gasPrice || !rolldownContract) {
      return null;
    }

    const isNativeToken = tokenAddress === NATIVE_TOKEN_ADDRESS;
    const amountInWei = web3.utils.toWei(amount, 'ether');

    const depositMethod = (() => {
      if (isNativeToken) {
        return rolldownContract.methods.deposit_eth();
      } else {
        return rolldownContract.methods.deposit(tokenAddress, amountInWei);
      }
    })();

    const gasAmount =
      (
        await depositMethod.estimateGas({
          from: userAddress,
          value: isNativeToken ? new Decimal(amountInWei).toHex() : undefined,
        })
      ).toString() || '0';
    const totalInWei = new Decimal(gasAmount).mul(gasPrice).toString();

    return {
      gasAmount,
      ethAmount: web3.utils.fromWei(totalInWei, 'ether'),
    };
  };

export const submitRollupDeposit =
  (
    transactionStore: TransactionStore,
    config: QueryOptional<Config>,
    rolldownContract: QueryOptional<Contract<typeof rolldownAbi>>,
    gasPrice: QueryOptional<string | null>,
    trackTx: (hash: TrackingTx) => void,
  ) =>
  async ({
    token,
    userAddress,
    amount,
    gasAmount,
    chain,
    trackingMeta,
  }: RollupDepositMutationParams) => {
    if (!config || !gasPrice || !userAddress || !rolldownContract || !chain || !gasAmount) {
      return;
    }

    const amountDec = new Decimal(`${amount}e${token.decimals}`);
    const depositMethod = (() => {
      if (token.isNative) {
        return rolldownContract.methods.deposit_eth();
      } else {
        return rolldownContract.methods.deposit(token.source.address, amountDec.toFixed());
      }
    })();

    if (!rolldownContract.options.address) {
      return;
    }

    const txParams = {
      account: userAddress as Hex,
      to: rolldownContract.options.address as Hex,
      data: depositMethod.encodeABI() as Hex,
      gasPrice: BigInt(gasPrice),
      gas: BigInt(gasAmount),
      value: token.isNative && amountDec ? BigInt(new Decimal(amountDec).toFixed()) : undefined,
    };

    const tx = new RollupChainTx(config, transactionStore)
      .create(TxType.RollupDeposit)
      .setMetadata({ tokens: [{ ...transformToAsset(token), amount }] })
      .setOptions({ showExplorerLink: false, doneOnTrigger: false, isVisible: false })
      .setTx(txParams, chain.explorerUrl)
      .build();

    const txHash = await tx.send();

    if (txHash) {
      trackTx({
        hash: txHash,
        status: TrackingTxStatus.Pending,
        type: TxType.RollupDeposit,
        amount: amount,
        account: userAddress,
        asset: transformToAsset(token),
        timestamp: new Date().toISOString(),
        trackingMeta,
      });
    }

    return !!txHash;
  };
