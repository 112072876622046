import { isString } from 'lodash-es';
import { QueryClient, QueryCache } from '@tanstack/react-query';
import { PersistQueryClientProviderProps } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { globalQueryErrorHandler } from '../../domains/error/queryError';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: globalQueryErrorHandler,
  }),
});

export type QueryOptional<T> = T | undefined | null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryServiceReturnType<T extends (...args: any) => any> = Awaited<
  ReturnType<ReturnType<T>>
>;

export const DEFAULT_QUERY_OPTIONS = {
  refetchOnWindowFocus: false,
  retry: false,
  staleTime: 1000 * 60 * 5,
};

export const QUERY_REFETCH_INTERVAL = {
  externalBalance: 5000,
  internalBalance: 5000,
  internalBalanceDepositsPending: 1000,
};

const queryKeysToPersist = ['price-discovery'];

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const queryPersistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  persister,
  dehydrateOptions: {
    shouldDehydrateQuery: (query) =>
      query.queryKey.some((key) => isString(key) && queryKeysToPersist.includes(key as string)),
  },
};
