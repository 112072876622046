import {
  MangataGenericEvent,
  MangataInstance,
  isSellAssetTransactionSuccessful,
  toBN,
  ExtrinsicSubscriptionData,
} from 'gasp-sdk';
import { some } from 'lodash-es';

import { BN } from '@polkadot/util';
import { TransactionStore, TxStatus, TxAsset, TxType } from '../../../transaction';
import { EnvConfig } from '../../../../envConfig';
import { SwapRoute, SwapTransactionError } from '../../Swap';
import { SdkTx } from '../../../transaction';
import { QueryOptional, WalletAccount } from '../../../../services';
import { Config } from 'wagmi';

export type SwapMutationProps = {
  route: SwapRoute;
  minAmountOut: BN;
  firstToken: TxAsset;
  secondToken: TxAsset;
  selectedAccount: WalletAccount;
  onDone?: () => void;
};

const getError = (txEvents: MangataGenericEvent[]) => {
  const failedEvent = txEvents.find((txEvent) => txEvent.error);
  const fallbackError = 'Unknown error';

  switch (true) {
    case !!failedEvent:
      return {
        name: failedEvent?.error?.name || fallbackError,
        msg: failedEvent?.error?.documentation.join(' '),
      };
    case some(txEvents, { method: SwapTransactionError.FailedDueToSlippage }):
    case some(txEvents, { method: SwapTransactionError.FailedOnAtomicSwap }):
      return {
        name: SwapTransactionError.FailedDueToSlippage,
        msg: null,
      };
  }

  return { name: fallbackError };
};

export const submitSwap =
  (
    sdk: MangataInstance | null,
    transactionStore: TransactionStore,
    config: QueryOptional<Config>,
  ) =>
  async (props: SwapMutationProps) => {
    const { route, minAmountOut, selectedAccount, firstToken, secondToken } = props;

    if (!selectedAccount || !sdk || !config) {
      return null;
    }

    const statusCallback = (txStatus: ExtrinsicSubscriptionData) => {
      if (txStatus.txHash && EnvConfig.EXPLORER_URL) {
        tx.setExplorerUrl(`${EnvConfig.EXPLORER_URL}/extrinsic/${txStatus.txHash.toHex()}`);
      }

      if (txStatus.status.isReady) {
        transactionStore.set(tx.id, { status: TxStatus.Pending });
        return;
      }
    };

    const extrinsicStatusCallback = (txEvents: MangataGenericEvent[]) => {
      if (isSellAssetTransactionSuccessful(txEvents)) {
        tx.done();
        props.onDone?.();
      } else {
        tx.doneWithError(getError(txEvents));
      }
    };

    const swapFn = () =>
      sdk.xyk.multiswapSellAsset({
        account: selectedAccount.address,
        amount: toBN(firstToken.amount, firstToken.decimals),
        tokenIds: route.map((token) => token.id),
        minAmountOut,
        txOptions: {
          wagmiConfig: config,
          statusCallback,
          extrinsicStatus: extrinsicStatusCallback,
        },
      });

    const tx = new SdkTx(transactionStore)
      .create(TxType.Swap)
      .setOptions({ doneOnTrigger: true })
      .setMetadata({
        tokens: [firstToken, secondToken],
        joinSymbolId: 'modal.transaction.header.for',
      })
      .setTx(swapFn)
      .build();

    await tx.send();
  };
