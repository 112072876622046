interface EnvOptions {
  withPrefix?: boolean;
  optional?: boolean;
}

export class EnvConfig {
  private static get<T extends string>(name: string, optionsOverride?: EnvOptions): T {
    const options = { withPrefix: true, ...optionsOverride };
    const env = import.meta.env;
    const key = options?.withPrefix ? `MANGATA_${name}` : name;
    const value = env[key];

    if (options?.optional && !value) {
      return '' as T;
    }

    if (!value) {
      throw new Error(`${key} is not defined in .env`);
    }
    return value as T;
  }

  static get isRollupEnv(): boolean {
    return this.CHAIN_ENV === 'rollup';
  }

  static get isKusamaEnv(): boolean {
    return this.CHAIN_ENV === 'kusama';
  }

  static APP_VERSION = this.get('APP_VERSION', { withPrefix: false, optional: true });
  static CHAIN_ENV = this.get('CHAIN_ENV');
  static ASSETS_URL = this.get('ASSETS_URL');
  static COINGECKO_API_URL = this.get('COINGECKO_API_URL');
  static COINGECKO_API_KEY = this.get('COINGECKO_API_KEY');
  static STASH_URL = this.get('STASH_URL');
  static API_URL =
    this.get('MODE', { withPrefix: false }) === 'test'
      ? this.get('TEST_API_URL')
      : this.get('API_URL');
  static MANGATA_PARACHAIN_ID = this.get('PARACHAIN_ID');
  static TOKEN_ID = this.get('TOKEN_ID');
  static ANNUAL_MGX_POOL_REWARDS = this.get('ANNUAL_MGX_POOL_REWARDS');
  static MAX_DELEGATION_COUNT = this.get('MAX_DELEGATION_COUNT');
  static CAPTCHA_SITEKEY = this.get('CAPTCHA_SITEKEY', { optional: true });
  static EXPLORER_URL = this.get('EXPLORER_URL', { optional: true });
  static GLEAP_API_KEY = this.get('GLEAP_API_KEY', { optional: true });
  static SENTRY_DSN = this.get('SENTRY_DSN', { optional: true });
  static SENTRY_ORG = this.get('SENTRY_ORG', { optional: true });
  static DEX_SENTRY_PROJECT = this.get('DEX_SENTRY_PROJECT', { optional: true });
  static SENTRY_ENV = this.get('SENTRY_ENV', { optional: true });
  static DEV_TOOLS = this.get('DEV_TOOLS', { optional: true });
}
