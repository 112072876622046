import { useMutation } from '@tanstack/react-query';
import { submitRollupWithdrawal } from '../services/rollupWithdrawalService';
import {
  DEFAULT_QUERY_OPTIONS,
  useApi,
  useTransactionStore,
  useTxTrackingStore,
} from '../../../../..';
import { useConfig } from 'wagmi';

export const useRollupWithdrawalMutation = () => {
  const api = useApi();
  const transactionStore = useTransactionStore();
  const config = useConfig();
  const { trackTx } = useTxTrackingStore();

  const rollupWithdrawalMutation = useMutation(
    submitRollupWithdrawal(api, transactionStore, config, trackTx),
    {
      ...DEFAULT_QUERY_OPTIONS,
    },
  );

  return {
    rollupWithdrawalMutation,
  };
};
