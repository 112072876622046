import { Token } from 'gasp-sdk';
import { pick } from 'lodash-es';
import { AssetRegistryAssetMetadata, StashToken } from '../../../../services';
import { Asset, AssetOrigin, AssetType } from '../../Token';
import { TxAsset } from '../../../transaction';
import { EnvConfig } from '../../../../envConfig';
import { RollupToken } from '../../../rollup/stash/RollupStash';

export const transformToAsset = (
  asset: Token | AssetRegistryAssetMetadata | TxAsset | RollupToken | StashToken,
): Asset => {
  const id = (() => {
    if ('tokenId' in asset) return asset.tokenId;
    if ('l2Id' in asset && asset.l2Id) return asset.l2Id;
    if ('id' in asset) return asset.id;
    if ('source' in asset && asset.source?.address) return asset.source.address;

    return '';
  })();

  return {
    ...pick(asset, ['name', 'symbol']),
    id,
    source: 'source' in asset ? asset.source : undefined,
    decimals: parseInt(asset.decimals.toString()),
    origin:
      'origin' in asset ? asset.origin : EnvConfig.isRollupEnv ? AssetOrigin.Native : undefined,
    type: !asset.symbol.includes('-') ? AssetType.Native : AssetType.LP,
  };
};
