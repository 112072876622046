import { Button, Container } from 'ui';
import { SwapWidget } from './widget/SwapWidget';
import { useSwapStore } from './store/useSwapStore';
import { animated, useSpring, useTransition } from '@react-spring/web';
import { ReactComponent as ChartIcon } from 'icons/chart.svg';
import { SwapChart } from './chart/SwapChart';
import cls from 'classnames';
import { FormattedMessage } from 'react-intl';
import { createRef, useEffect } from 'react';
import { TokenLaunchBanner } from './components/TokenLaunchBanner/TokenLaunchBanner';

export enum SwapInputType {
  FirstToken = 'firstToken',
  SecondToken = 'secondToken',
}

export function SwapPage() {
  const {
    firstToken,
    secondToken,
    isChartVisible,
    setChartVisible,
    areSettingsOpen,
    setIsTokenLaunchBannerVisible,
    isTokenLaunchBannervisible,
  } = useSwapStore();
  const chartRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (areSettingsOpen && isChartVisible) {
      setChartVisible(false);
    }
  }, [areSettingsOpen, isChartVisible, setChartVisible]);

  const onChartTransitionEnd = () => {
    if (isChartVisible) {
      chartRef.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const chartTransition = useTransition(isChartVisible, {
    from: { height: 0, opacity: 0 },
    enter: { height: 165, opacity: 1 },
    leave: { height: 0, opacity: 0 },
    onRest: onChartTransitionEnd,
  });

  const tokenLaunchBannerSpring = useSpring({
    opacity: !areSettingsOpen ? 1 : 0,
    transform: !areSettingsOpen ? 'translateY(0)' : 'translateY(-10px)',
    delay: areSettingsOpen ? 0 : 400,
    config: { duration: areSettingsOpen ? 0 : 400 },
    onRest: () => {
      setIsTokenLaunchBannerVisible(true);
    },
    immediate: isTokenLaunchBannervisible && areSettingsOpen,
  });

  const toggleContent = () => {
    setChartVisible(!isChartVisible);
  };

  return (
    <Container alignItems="center" className="h-full">
      <Container
        alignItems="center"
        justifyContent="center"
        className="pt-[80px] my-auto w-full"
        column
      >
        <SwapWidget />
        <animated.div style={tokenLaunchBannerSpring} className="w-full mt-4">
          <TokenLaunchBanner />
        </animated.div>
        <Button
          variant="outlined"
          size="s"
          className={cls(
            'mt-10 text-secondary normal-case h-10',
            (!(firstToken && secondToken) || areSettingsOpen) && 'invisible',
          )}
          isDisabled={!firstToken || !secondToken}
          onClick={toggleContent}
        >
          {!isChartVisible && (
            <ChartIcon className="fill-icon-secondary stroke-icon-secondary mr-1" />
          )}{' '}
          <FormattedMessage id={isChartVisible ? 'swap.hide.chart.cta' : 'swap.show.chart.cta'} />
        </Button>

        {chartTransition((style, item) =>
          item ? (
            <animated.div style={style} className="w-full">
              <SwapChart className="pb-10 h-[310px]" ref={chartRef} />
            </animated.div>
          ) : null,
        )}
      </Container>
    </Container>
  );
}
