export enum KeypairTypeValue {
  SR25519 = 'sr25519',
  ED25519 = 'ed25519',
  ECDSA = 'ecdsa',
  Ethereum = 'ethereum',
  MetamaskEthereum = 'metamaskEthereum',
}

export const XCMKeypairTypes = [
  KeypairTypeValue.SR25519,
  KeypairTypeValue.ED25519,
  KeypairTypeValue.ECDSA,
  KeypairTypeValue.Ethereum,
];

export const EthereumKeyPairTypes = [KeypairTypeValue.Ethereum];
