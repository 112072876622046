import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction';
import { deactivateLiquidityFor3rdPartyRewards } from '../services/deactivateLiqFor3rdPartyRewardsService';
import { useApi } from '../../../../services';
import { useAccounts } from '../../../account';
import { useConfig } from 'wagmi';

export const useDeactivateLiqudityFor3rdPartyRewardsMutation = () => {
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const deactivateLiqFor3rdPartyRewardsMutation = useMutation(
    ['deactivate-liquidity-for-3rd-party-rewards'],
    deactivateLiquidityFor3rdPartyRewards(api, selectedAccount?.address, config, transactionStore),
  );

  return { deactivateLiqFor3rdPartyRewardsMutation };
};
