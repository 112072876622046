import axios from 'axios';
import { EnvConfig } from '../../../envConfig';
import { CoingeckoContractInfo } from '../Coingecko';
import { QueryFunctionContext } from '@tanstack/react-query';
import { NATIVE_TOKEN_ADDRESS } from '../../rollup';
import { QueryOptional } from '../../../services';

export type CoingeckoContractInfoQueryKey = Readonly<
  [queryKey: string, address: QueryOptional<string>, chainKey: QueryOptional<string>]
>;

export const fetchCoingeckoContractInfo = async ({
  queryKey: [, address, chainKey],
}: QueryFunctionContext<CoingeckoContractInfoQueryKey>) => {
  if (!address || !chainKey || address === NATIVE_TOKEN_ADDRESS) {
    return null;
  }

  const coingeckoChain =
    {
      Ethereum: 'eth',
      Arbitrum: 'arbitrum',
    }[chainKey] || chainKey;

  const { data: coingeckoContractData } = await axios.get<CoingeckoContractInfo>(
    `${EnvConfig.COINGECKO_API_URL}/onchain/networks/${coingeckoChain}/tokens/${address}/info`,
    {
      params: {
        x_cg_pro_api_key: EnvConfig.COINGECKO_API_KEY,
      },
    },
  );

  return coingeckoContractData;
};
