import cls from 'classnames';
import { TransitionEventHandler, useState } from 'react';
import { ClassName, TestId, useAccounts } from 'core';
import { Container } from 'ui';

import { WalletHeader } from '../Header/Header';
import { useWalletUIStore } from '../../store/useWalletUIStore';
import { twMerge } from 'tailwind-merge';

export interface WalletWrapperProps extends ClassName, TestId {
  children(onClose: () => void): React.ReactNode;
  onTransitionEnd?(isOpen: boolean): void;
}

export function WalletWrapper({
  children,
  className,
  'data-testid': testId = 'walletWrapper',
  onTransitionEnd,
}: WalletWrapperProps) {
  const { isWalletOpen, setIsWalletOpen } = useWalletUIStore();
  const { selectedAccount, selectedWallet } = useAccounts();

  const [contentVisible, setContentVisible] = useState(false);
  const [animating, setAnimating] = useState(false);

  const CONTAINER_CLASS = twMerge(
    cls(
      'group transition-all duration-300 [&>*]:duration-300 overflow-hidden pointer-events-auto',
      'rounded-xl bg-widget border border-transparent shadow-xl',
      isWalletOpen && 'w-[360px] h-[100%] max-h-full',
      !isWalletOpen && !selectedAccount && 'hover:bg-base hover:border-strong hover:w-[265px]',
      !isWalletOpen && 'max-h-[56px] w-[215px] h-[56px]',
      selectedAccount && !isWalletOpen && 'w-[280px]',
      className,
    ),
  );

  const CONTENT_CLASS = cls(
    'transition-all w-full relative z-20 pt-0 overflow-hidden',
    isWalletOpen && 'opacity-100 visible',
    !isWalletOpen && 'opacity-0 invisible',
  );

  const handleTransitionEnd: TransitionEventHandler<HTMLDivElement> = (e) => {
    if (onTransitionEnd) {
      onTransitionEnd(isWalletOpen);
    }

    if (animating && e.propertyName === 'max-height') {
      setAnimating(false);
    }
  };

  const toggleOpen = () => {
    setContentVisible(!contentVisible);
    setAnimating(true);
    setIsWalletOpen(!isWalletOpen);
  };

  return (
    <Container
      justifyContent="end"
      className={cls(
        'w-min-content h-screen max-h-screen p-6 pl-2 z-20 transition-all duration-300 pointer-events-none',
      )}
      onTransitionEnd={handleTransitionEnd}
    >
      <Container alignItems="center" column className={CONTAINER_CLASS} data-testid={testId}>
        <WalletHeader
          selectedWallet={selectedWallet}
          selectedAccount={selectedAccount}
          data-testid={`${testId}-header`}
          animating={animating}
        />
        {isWalletOpen && !animating && (
          <Container column className={CONTENT_CLASS} data-testid={`${testId}-content`}>
            <>{children(toggleOpen)}</>
          </Container>
        )}
      </Container>
    </Container>
  );
}
