import { ApiPromise } from '@polkadot/api';
import { TransactionStore, ExtrinsicTx, TxType } from '../../../transaction';
import { QueryOptional } from '../../../../services';
import { BN } from '@polkadot/util';
import { Config } from 'wagmi';

interface DeactivateLiquidityFor3rdPartyRewardsParams {
  id: string;
  rewardTokenId: string;
  amount: BN;
  symbol: string;
}

export const get3rdPartyLiquidityDeactivationTxs = (
  api: ApiPromise,
  liqTokenId: string,
  activeLiqs: [string, BN][],
) =>
  activeLiqs.map(([id, amount]) =>
    api.tx.proofOfStake.deactivateLiquidityFor3rdpartyRewards(liqTokenId, amount, id),
  );

export const deactivateLiquidityFor3rdPartyRewards =
  (
    api: ApiPromise | null,
    address: string | undefined,
    config: QueryOptional<Config>,
    transactionStore: TransactionStore,
  ) =>
  async ({ id, rewardTokenId, amount }: DeactivateLiquidityFor3rdPartyRewardsParams) => {
    if (!api || !address || !config) {
      return null;
    }

    const extrinsic = api.tx.proofOfStake.deactivateLiquidityFor3rdpartyRewards(
      id,
      amount,
      rewardTokenId,
    );

    return new ExtrinsicTx(api, transactionStore, config, address)
      .create(TxType.DeactivateLPFor3rdPartyRewards)
      .setTx(extrinsic)
      .build()
      .send();
  };
