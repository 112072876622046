import { useMutation } from '@tanstack/react-query';
import { useRollupGasPriceQuery, useTransactionStore } from '../../../..';
import { useRolldownContract } from '../../../contract/useRolldownContract';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { submitRollupApprove } from '../services/rollupApproveService';
import { useConfig } from 'wagmi';

export const useRollupApproveMutation = (chainId: QueryOptional<string>) => {
  const { contract } = useRolldownContract(chainId);
  const config = useConfig();
  const transactionStore = useTransactionStore();
  const { rollupGasPriceQuery } = useRollupGasPriceQuery(chainId);

  const rollupApproveMutation = useMutation(
    submitRollupApprove(transactionStore, config, contract, rollupGasPriceQuery.data),
    {
      ...DEFAULT_QUERY_OPTIONS,
    },
  );

  const isApproveInProgress = rollupApproveMutation.isLoading;

  return {
    rollupApproveMutation,
    isApproveInProgress,
  };
};
