import { StorageKey, Option } from '@polkadot/types';
import { MangataTypesAssetsL1Asset } from '@polkadot/types/lookup';
import { u32 } from '@polkadot/types/primitive';

type L1AddressesMapType = Map<MangataTypesAssetsL1Asset['type'], string>;
type L2AddressesMapType = Map<string, string>;

export interface RollupTokenAddressMaps {
  l2ToL1: Map<string, L1AddressesMapType>;
  l1ToL2: Map<MangataTypesAssetsL1Asset['type'], L2AddressesMapType>;
}

export const transformRollupTokenAddresMaps = (
  _data: [StorageKey<[u32]>, Option<MangataTypesAssetsL1Asset>][] | null,
): RollupTokenAddressMaps | null => {
  if (!_data || _data.length === 0) {
    return null;
  }

  const l2ToL1 = new Map<string, Map<MangataTypesAssetsL1Asset['type'], string>>();
  const l1ToL2 = new Map<MangataTypesAssetsL1Asset['type'], L2AddressesMapType>();

  _data.forEach(([storageKey, _addressData]) => {
    const tokenId = storageKey.args[0].toString();
    const addressData = _addressData.unwrapOr(null);

    if (addressData) {
      const l1Address = addressData[`as${addressData.type}`].toString();
      const l2ChainName = addressData.type;
      const l1Map = l2ToL1.get(tokenId) || new Map<MangataTypesAssetsL1Asset['type'], string>();
      const l2Map = l1ToL2.get(l2ChainName) || new Map<string, string>();

      l1Map.set(l2ChainName, l1Address);
      l2ToL1.set(tokenId, l1Map);

      l2Map.set(l1Address, tokenId);
      l1ToL2.set(l2ChainName, l2Map);
    }
  });

  return { l2ToL1, l1ToL2 };
};
