import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { Asset } from '../../token';
import { TxType } from '../Transaction';

export enum TrackingTxStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export interface TrackingTx {
  hash: string;
  status: TrackingTxStatus;
  type: TxType.RollupDeposit | TxType.RollupWithdrawal;
  amount: string;
  asset: Asset;
  account: string;
  timestamp: string;
  trackingMeta: {
    refBalance: string;
  };
}

export interface TxTrackingStore {
  transactions: TrackingTx[];
  trackTx: (tx: TrackingTx) => void;
  removeTx: (tx: TrackingTx) => void;
  updateTxStatus: (hash: string, status: TrackingTxStatus) => void;
  updateRefBalance: (hash: string, balance: string) => void;
}

const STORAGE_KEY = 'tracking';

export const useTxTrackingStore = create(
  persist<TxTrackingStore>(
    (set) => ({
      transactions: [],
      trackTx: (tx) =>
        set((state) => ({
          transactions: [...state.transactions, tx],
        })),
      removeTx: (tx) =>
        set((state) => ({
          transactions: state.transactions.filter((item) => item.hash !== tx.hash),
        })),
      updateTxStatus: (hash, status) =>
        set((state) => ({
          transactions: state.transactions.map((item) =>
            item.hash === hash ? { ...item, status } : item,
          ),
        })),
      updateRefBalance: (hash, balance) =>
        set((state) => ({
          transactions: state.transactions.map((item) =>
            item.hash === hash ? { ...item, trackingMeta: { refBalance: balance } } : item,
          ),
        })),
    }),
    {
      name: STORAGE_KEY,
      storage: createJSONStorage(() => localStorage),
      version: 2,
    },
  ),
);
