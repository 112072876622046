import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { SentryTag, useAccounts } from 'core';
import { ConnectedLayout } from './layouts/Connected/ConnectedLayout';
import { SelectWalletLayout } from './layouts/SelectWallet/SelectWalletLayout';
import { SelectAccountModal } from './layouts/SelectAccount/SelectAccountModal';
import { WalletStatus, useWalletUIStore } from './store/useWalletUIStore';
import { WalletWrapper } from './components';
import { WalletProps } from './Wallet';
import { GaspNotConnectedLayout } from './layouts/NotConnected/GaspNotConnectedLayout';
import { useTxTracking } from '../Tx/useTxTracking';

export const GaspWallet = ({ 'data-testid': testId = 'wallet' }: WalletProps) => {
  const { selectedAccount } = useAccounts();
  const { setStatus, status } = useWalletUIStore();

  useTxTracking();

  useEffect(() => {
    if (selectedAccount) {
      Sentry.setTags({
        [SentryTag.UserAddress]: selectedAccount.address,
        [SentryTag.UserAccountType]: selectedAccount.type,
      });
      setStatus(WalletStatus.Connected);
    } else {
      setStatus(WalletStatus.NotConnected);
    }
  }, [selectedAccount, setStatus]);

  const handleConnectWallet = () => {
    setStatus(WalletStatus.SelectWallet);
  };

  const handleTransitionEnd = (isOpen: boolean) => {
    if (!isOpen && status === WalletStatus.SelectWallet && !selectedAccount) {
      setStatus(WalletStatus.NotConnected);
    }
  };

  const renderWalletWrapper = () => {
    return (
      <WalletWrapper
        data-testid={`${testId}-wrapper`}
        className="h-full z-20"
        onTransitionEnd={handleTransitionEnd}
      >
        {(onClose) => {
          switch (status) {
            case WalletStatus.SelectWallet:
              return (
                <SelectWalletLayout
                  data-testid={`${testId}-${WalletStatus.SelectWallet}`}
                  onClose={onClose}
                />
              );
            case WalletStatus.Connected:
              return <ConnectedLayout data-testid={`${testId}-${WalletStatus.Connected}`} />;
            default:
              return (
                <GaspNotConnectedLayout
                  data-testid={`${testId}-${WalletStatus.NotConnected}`}
                  onClose={onClose}
                  onConnectWallet={handleConnectWallet}
                />
              );
          }
        }}
      </WalletWrapper>
    );
  };

  return (
    <>
      {renderWalletWrapper()}
      <SelectAccountModal />
    </>
  );
};
