import React from 'react';
import { Decimal } from 'decimal.js';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';

import uiEnMessages from 'ui/src/i18n/en';
import gaspEnMessages from './i18n/en';
import { ToastProvider } from 'ui';
import { EnvConfig, initSentry, queryClient, queryPersistOptions, initGleap } from 'core';
import { DevToolsProvider } from './layouts/devTools';
import { WagmiProvider, Web3Provider } from 'modules';
import { App } from './App';

import './index.css';
import { HelmetWrapper } from './layouts/helmet-wrapper/HelmetWrapper';
import { HelmetProvider } from 'react-helmet-async';

const SIGNIFICANT_DIGITS_PRECISION = 70;
const enMessages = { ...uiEnMessages, ...gaspEnMessages };

Decimal.set({ precision: SIGNIFICANT_DIGITS_PRECISION });

initSentry(EnvConfig.SENTRY_DSN);
initGleap(EnvConfig.GLEAP_API_KEY);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <IntlProvider messages={enMessages} locale="en" defaultLocale="en">
      <HelmetProvider>
        <PersistQueryClientProvider client={queryClient} persistOptions={queryPersistOptions}>
          <WagmiProvider>
            <BrowserRouter>
              <Web3Provider>
                <HelmetWrapper />
                <App />
                <ToastProvider />
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                <DevToolsProvider />
              </Web3Provider>
            </BrowserRouter>
          </WagmiProvider>
        </PersistQueryClientProvider>
      </HelmetProvider>
    </IntlProvider>
  </React.StrictMode>,
);
