import { BN_ZERO, MangataGenericEvent, toBN } from 'gasp-sdk';
import {
  ExtrinsicError,
  TransactionErrorEvent,
  TransactionStore,
  ExtrinsicTx,
  TxAsset,
  TxType,
} from '../../../../transaction';
import {
  ActivateLiquidityReserveSource,
  QueryOptional,
  ReserveSourceType,
  StakeReserveSource,
} from '../../../../../services';
import { ApiPromise } from '@polkadot/api';
import { ParachainStakingDelegationRequest } from '@polkadot/types/lookup';
import { ReserveSourceForTx } from '../../../../balance';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { createLiqActivationTxsForStaking } from '../../../../pool/activateLiquidity/services/ActivateLiquidityMutationService';
import { Config } from 'wagmi';

const getError = (txEvents: MangataGenericEvent[] | null | undefined) => {
  const failedEvent = txEvents?.find((txEvent) => txEvent.error);

  switch (true) {
    case !!failedEvent:
      return {
        name: failedEvent?.error?.name || ExtrinsicError.Unknown,
        msg: failedEvent?.error?.documentation.join(' '),
      };
  }

  return { name: ExtrinsicError.Unknown };
};

const createExecuteDelegationRequestTx = (
  api: ApiPromise,
  delegatorAddress: string,
  collatorAddress: string,
  sources: ReserveSourceForTx<StakeReserveSource>,
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  asset: TxAsset,
  request: ParachainStakingDelegationRequest,
) => {
  if (request.action.isDecrease) {
    return api.tx.parachainStaking.executeDelegationRequest(
      delegatorAddress,
      collatorAddress,
      null,
    );
  }

  const sufficientAmountSingleSource = sources.available.find(([, amount]) => {
    return toBN(asset.amount).sub(amount).lte(BN_ZERO);
  });

  if (sufficientAmountSingleSource) {
    return api.tx.parachainStaking.executeDelegationRequest(
      delegatorAddress,
      collatorAddress,
      sufficientAmountSingleSource[0],
    );
  }

  const txs: SubmittableExtrinsic<'promise'>[] = [];

  if (activateLiqSources.hasAvailable) {
    txs.push(...createLiqActivationTxsForStaking(api, activateLiqSources, asset));
  }

  txs.push(
    api.tx.parachainStaking.executeDelegationRequest(
      delegatorAddress,
      collatorAddress,
      ReserveSourceType.ActivatedUnstakedReserves,
    ),
  );

  return txs.length === 1 ? txs[0] : api.tx.utility.batchAll(txs);
};

export const executeDelegationRequest =
  (
    api: ApiPromise | null,
    delegatorAddress: string | undefined,
    collatorAddress: QueryOptional<string>,
    config: QueryOptional<Config>,
    transactionStore: TransactionStore,
  ) =>
  async ({
    asset,
    request,
    sources,
    activateLiqSources,
  }: {
    asset: TxAsset;
    request: ParachainStakingDelegationRequest;
    sources: ReserveSourceForTx<StakeReserveSource>;
    activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>;
  }) => {
    if (!api || !config || !collatorAddress || !delegatorAddress) {
      return null;
    }

    const extrinsic = createExecuteDelegationRequestTx(
      api,
      delegatorAddress,
      collatorAddress,
      sources,
      activateLiqSources,
      asset,
      request,
    );

    if (!extrinsic) {
      return null;
    }

    const tx = new ExtrinsicTx(api, transactionStore, config, delegatorAddress)
      .create(request.action.isIncrease ? TxType.ConfirmStakeIncrease : TxType.ConfirmStakeDecrease)
      .setMetadata({ tokens: [asset], amountPrefix: request.action.isIncrease ? '+' : '-' })
      .setTx(extrinsic)
      .build();

    const res = await tx.send();

    if (res?.some((event) => event.method === TransactionErrorEvent.ExtrinsicFailed)) {
      tx.doneWithError(getError(res));
      return false;
    }
  };
