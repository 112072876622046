import { Container, Text } from 'ui';
import bg from './images/token-launch-bg.png';
import { ClassName } from 'core';
import cls from 'classnames';
import { useEffect, useState } from 'react';

const MAILERLITE_FORM_ID = 'FFh8kJ';

export function TokenLaunchBanner({ className }: ClassName) {
  const [isMailerLiteAvailable, setIsMailerLiteAvailable] = useState(window.ml !== undefined);

  const showModal = () => {
    window.ml?.('show', MAILERLITE_FORM_ID, true);
  };

  useEffect(() => {
    if (window.ml && !isMailerLiteAvailable) {
      setIsMailerLiteAvailable(true);
      return;
    }

    const interval = setInterval(() => {
      if (window.ml) {
        clearInterval(interval);
        setIsMailerLiteAvailable(true);
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [isMailerLiteAvailable]);

  return isMailerLiteAvailable ? (
    <Container
      onClick={showModal}
      fullWidth
      className={cls('relative rounded-xl bg-highlight overflow-hidden cursor-pointer', className)}
    >
      <img src={bg} alt="background" />
      <Container column className="pt-4 pr-8 pl-2">
        <Text
          color="inverted"
          weight="semibold"
          className="text-[18px]"
          id="tokenLaunchBanner.title"
        />
        <Text color="inverted" id="tokenLaunchBanner.desc" />
      </Container>
    </Container>
  ) : null;
}
