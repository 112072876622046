import { useMutation } from '@tanstack/react-query';
import { submitRollupDeposit } from '../services/rollupDepositService';
import {
  DEFAULT_QUERY_OPTIONS,
  QueryOptional,
  useRollupGasPriceQuery,
  useTransactionStore,
  useTxTrackingStore,
} from '../../../../..';
import { useRolldownContract } from '../../useRolldownContract';
import { useConfig } from 'wagmi';

export const useRollupDepositMutation = (chainId: QueryOptional<string>) => {
  const { contract } = useRolldownContract(chainId);
  const transactionStore = useTransactionStore();
  const { rollupGasPriceQuery } = useRollupGasPriceQuery(chainId);
  const { trackTx } = useTxTrackingStore();
  const config = useConfig();

  const rollupDepositMutation = useMutation({
    mutationFn: submitRollupDeposit(
      transactionStore,
      config,
      contract,
      rollupGasPriceQuery.data,
      trackTx,
    ),
    mutationKey: ['rollup-deposit-submit'],
    ...DEFAULT_QUERY_OPTIONS,
  });

  return {
    rollupDepositMutation,
  };
};
