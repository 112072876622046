import { ClassName, WalletSource } from 'core';
import { isEmpty } from 'lodash-es';
import { Container, Text } from 'ui';
import { WalletItem } from '../WalletItem/WalletItem';
import { useConnect } from 'wagmi';

const wallets = [{ id: WalletSource.Metamask, url: 'https://metamask.io', isMobile: false }];

export function PopularWallets({ className }: ClassName) {
  const { connectors } = useConnect();
  const list = wallets.filter(
    (wallet) => !connectors.some((connector) => connector.name.toLowerCase() === wallet.id),
  );

  const renderWallets = () => {
    return list.map((wallet, index) => {
      return <WalletItem key={index} id={wallet.id} url={wallet.url} isMobile={wallet.isMobile} />;
    });
  };

  return !isEmpty(list) ? (
    <Container column fullWidth className={className}>
      <Text
        id="wallet.connect.popularWallets"
        type="title-4"
        color="secondary"
        className="ml-2 mb-4"
      />
      <Container fullWidth column>
        {renderWallets()}
      </Container>
    </Container>
  ) : null;
}
