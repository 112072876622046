import { ApiPromise } from '@polkadot/api';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS } from 'gasp-sdk';
import { Decimal } from 'decimal.js';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { QueryFunctionContext } from '@tanstack/react-query';
import { TransactionStore, ExtrinsicTx, TxType } from '../../../transaction';
import {
  getPaymentInfo,
  StashChannel,
  StashToken,
  withdrawalExtrinsic,
  XcmOperation,
  XcmTransferValidationService,
  QueryOptional,
  AnyMangataError,
  WalletAccount,
} from '../../../../services';
import { transformToAsset } from '../../../token';
import { Config } from 'wagmi';

type GetWithdrawalExtrinsicQueryKey = [
  string,
  string | undefined,
  string | null,
  StashToken | null,
  StashChannel | null,
];

interface SubmitWithdrawalProps {
  api: ApiPromise | null;
  amount: string;
  originAccount: WalletAccount;
  asset: StashToken;
  channel: StashChannel;
  destinationAccount: WalletAccount;
  extrinsic?: SubmittableExtrinsic<'promise'> | null;
  onDone?: () => void;
}

export const getWithdrawalExtrinsic =
  (api: ApiPromise | null, destinationAccount: WalletAccount | null) =>
  async ({ queryKey }: QueryFunctionContext<GetWithdrawalExtrinsicQueryKey>) => {
    const [, address, amount, asset, channel] = queryKey;

    if (!api || !address || !amount || !asset || !channel || !destinationAccount) {
      return null;
    }

    const ex = withdrawalExtrinsic({ destinationAccount, amount, asset, channel, api });
    return ex;
  };

export const getWithdrawalFee =
  (
    extrinsic: QueryOptional<SubmittableExtrinsic<'promise'>>,
    selectedAccount: QueryOptional<WalletAccount>,
  ) =>
  async () => {
    if (!extrinsic || !selectedAccount?.address) {
      return null;
    }

    const paymentInfo = await getPaymentInfo(extrinsic, selectedAccount.address);

    return new Decimal(paymentInfo.fee).div(`1e${BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS}`).toFixed();
  };

export const submitWithdrawal =
  (
    mangataApi: ApiPromise | null,
    transactionStore: TransactionStore,
    config: Config | null | undefined,
  ) =>
  async ({
    amount,
    originAccount,
    extrinsic,
    asset,
    channel,
    api,
    onDone,
  }: SubmitWithdrawalProps) => {
    if (!mangataApi || !extrinsic || !api || !config) {
      return false;
    }
    const originAddress = originAccount.address;

    const tx = new ExtrinsicTx(api, transactionStore, config, originAddress)
      .create(TxType.Withdraw)
      .setOptions({ showExplorerLink: false, useDefaultSigner: true, doneOnTrigger: true, onDone })
      .setMetadata({ tokens: [{ ...transformToAsset(asset), amount }] })
      .setTx(extrinsic)
      .build();

    await tx.send();

    try {
      await new XcmTransferValidationService(
        mangataApi,
        api,
        originAddress,
        asset,
        channel,
        XcmOperation.Withdrawal,
      ).verify();

      tx.done();
    } catch (e) {
      const error = e as AnyMangataError;
      tx.doneWithError({ name: error.message });
    }
  };
