import { Children, RollupStashChain, useRollupChainsQuery, initialWagmiConfig } from 'core';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { type Chain, http, defineChain } from 'viem';
import { WagmiProvider as Provider, createConfig } from 'wagmi';

export const defineWagmiChain = ({
  chainId,
  nativeToken,
  rpcUrl,
  rolldownContract,
  name,
  explorerUrl,
}: RollupStashChain) =>
  defineChain({
    id: Number(chainId),
    name: name,
    nativeCurrency: {
      name: nativeToken.name,
      symbol: nativeToken.symbol,
      decimals: parseInt(nativeToken.decimals),
    },
    rpcUrls: {
      default: { http: [rpcUrl] },
    },
    blockExplorers: !isEmpty(explorerUrl)
      ? {
          default: { name: 'Explorer', url: explorerUrl },
        }
      : undefined,
    contracts: {
      rolldown: {
        address: rolldownContract,
      },
    },
  });

export const WagmiProvider = ({ children }: Children) => {
  const { rollupChainsQuery } = useRollupChainsQuery();

  const stashChains = rollupChainsQuery.data?.map((stashChain) => defineWagmiChain(stashChain));

  const stashConfig = useMemo(() => {
    if (stashChains && stashChains.length > 0) {
      return createConfig({
        chains: stashChains as unknown as readonly [Chain, ...Chain[]],
        transports: stashChains.reduce(
          (acc, chain) => ({
            ...acc,
            [chain.id]: http(),
          }),
          {},
        ),
      });
    }
  }, [stashChains]);

  if (!stashConfig) {
    return (
      <Provider config={initialWagmiConfig} reconnectOnMount={false}>
        {children}
      </Provider>
    );
  }

  return <Provider config={stashConfig}>{children}</Provider>;
};
