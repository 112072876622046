import { MangataGenericEvent, toBN } from 'gasp-sdk';
import {
  ExtrinsicError,
  TransactionErrorEvent,
  TransactionStore,
  ExtrinsicTx,
  TxAsset,
  TxType,
} from '../../../../transaction';
import {
  ReserveSourceType,
  QueryOptional,
  StakeReserveSource,
  ActivateLiquidityReserveSource,
} from '../../../../../services';
import { ApiPromise } from '@polkadot/api';
import { createLiqActivationTxsForStaking } from '../../../../pool/activateLiquidity/services/ActivateLiquidityMutationService';
import { ReserveSourceForTx } from '../../../../balance';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { BN_ZERO } from '@polkadot/util';
import { Config } from 'wagmi';

interface ScheduleBondMoreParams {
  collatorAddress: string;
  txAsset: TxAsset;
  sources: ReserveSourceForTx<StakeReserveSource>;
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>;
  onDone: () => void;
}

const resolveError = (txEvents: MangataGenericEvent[]) => {
  const failedEvent = txEvents.find((txEvent) => txEvent.error);

  return {
    name: failedEvent?.error?.name || ExtrinsicError.Unknown,
    msg: failedEvent?.error?.documentation.join(' '),
  };
};

export const submitScheduleBondMore =
  (
    api: ApiPromise | null,
    address: string | undefined,
    config: QueryOptional<Config>,
    transactionStore: TransactionStore,
  ) =>
  async ({
    collatorAddress,
    txAsset,
    sources,
    activateLiqSources,
    onDone,
  }: ScheduleBondMoreParams) => {
    if (!api || !address || !config || !sources) {
      return null;
    }

    const extrinsic = createBondMoreTx(api, collatorAddress, sources, activateLiqSources, txAsset);

    const tx = new ExtrinsicTx(api, transactionStore, config, address)
      .create(TxType.StakeChange)
      .setOptions({ onDone })
      .setMetadata({ tokens: [txAsset], amountPrefix: '+' })
      .setTx(extrinsic)
      .build();

    const res = await tx.send();

    if (res?.some((event) => event.method === TransactionErrorEvent.ExtrinsicFailed)) {
      tx.doneWithError(resolveError(res));
    }
  };

const createBondMoreTx = (
  api: ApiPromise,
  collatorAddress: string,
  sources: ReserveSourceForTx<StakeReserveSource>,
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  txAsset: TxAsset,
) => {
  const sufficientAmountSingleSource = sources.available.find(([, amount]) => {
    return toBN(txAsset.amount).sub(amount).lte(BN_ZERO);
  });

  if (sufficientAmountSingleSource) {
    return api.tx.parachainStaking.scheduleDelegatorBondMore(
      collatorAddress,
      toBN(txAsset.amount),
      sufficientAmountSingleSource[0],
    );
  }

  const txs: SubmittableExtrinsic<'promise'>[] = [];

  if (activateLiqSources.hasAvailable) {
    txs.push(...createLiqActivationTxsForStaking(api, activateLiqSources, txAsset));
  }

  txs.push(
    api.tx.parachainStaking.scheduleDelegatorBondMore(
      collatorAddress,
      toBN(txAsset.amount),
      ReserveSourceType.ActivatedUnstakedReserves,
    ),
  );

  return txs.length === 1 ? txs[0] : api.tx.utility.batchAll(txs);
};
