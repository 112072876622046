import { useMutation } from '@tanstack/react-query';
import { useTransactionStore } from '../../../transaction';
import { useSDK } from '../../../../services/sdk/useSDK';
import { submitSwap } from './SwapSubmitService';
import { useConfig } from 'wagmi';

export const useSwapMutation = () => {
  const transactionStore = useTransactionStore();
  const sdk = useSDK();
  const config = useConfig();

  const swapMutation = useMutation(submitSwap(sdk, transactionStore, config));

  return { swapMutation };
};
