import { Container, IconButton, Text } from 'ui';
import { TestId, useModalStore, useWallet } from 'core';

import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { InstalledWallets } from './components/InstalledWallets/InstalledWallets';
import { PopularWallets } from './components/PopularWallets/PopularWallets';

interface ConnectWalletLayoutProps extends TestId {
  onClose(): void;
}

export const SelectWalletLayout = ({
  onClose,
  'data-testid': testId,
}: ConnectWalletLayoutProps) => {
  const { setSelectedWallet } = useWallet();
  const { openAccountSettings } = useModalStore();

  const handleWalletSelect = (wallet: string) => {
    setSelectedWallet(wallet);
    openAccountSettings();
  };

  const renderContent = () => {
    return (
      <Container column className="w-full">
        <InstalledWallets onSelect={handleWalletSelect} />
        <PopularWallets className="mt-4" />
      </Container>
    );
  };

  return (
    <Container column className="w-full h-full p-4" alignItems="stretch" data-testid={testId}>
      <Container className="pl-2 mb-4" justifyContent="space-between" alignItems="center">
        <Text type="title-2" id="wallet.connectWallet.header" />
        <IconButton Icon={CloseIcon} onClick={onClose} size="s" data-testid={`${testId}-close`} />
      </Container>
      <Container column justifyContent="space-between" className=" h-full">
        {renderContent()}
      </Container>
    </Container>
  );
};
