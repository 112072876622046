import { MangataGenericEvent } from 'gasp-sdk';
import {
  ExtrinsicError,
  TransactionErrorEvent,
  TransactionStore,
  ExtrinsicTx,
  TxAsset,
  TxType,
} from '../../../../transaction';
import { QueryOptional } from '../../../../../services';
import { ApiPromise } from '@polkadot/api';
import { Config } from 'wagmi';

interface ScheduleRevokeDelegationParams {
  collatorAddress: string;
  txAsset: TxAsset;
  onDone: () => void;
}

const resolveError = (txEvents: MangataGenericEvent[]) => {
  const failedEvent = txEvents.find((txEvent) => txEvent.error);

  return {
    name: failedEvent?.error?.name || ExtrinsicError.Unknown,
    msg: failedEvent?.error?.documentation.join(' '),
  };
};

export const submitScheduleRevokeDelegation =
  (
    api: ApiPromise | null,
    address: string | undefined,
    config: QueryOptional<Config>,
    transactionStore: TransactionStore,
  ) =>
  async ({ collatorAddress, txAsset, onDone }: ScheduleRevokeDelegationParams) => {
    if (!api || !address || !config || !txAsset) {
      return null;
    }

    const extrinsic = api.tx.parachainStaking.scheduleRevokeDelegation(collatorAddress);
    const tx = new ExtrinsicTx(api, transactionStore, config, address)
      .create(TxType.StakeChange)
      .setMetadata({ tokens: [txAsset], amountPrefix: '-' })
      .setOptions({ onDone })
      .setTx(extrinsic)
      .build();

    const res = await tx.send();

    if (res?.some((event) => event.method === TransactionErrorEvent.ExtrinsicFailed)) {
      tx.doneWithError(resolveError(res));
    }
  };
