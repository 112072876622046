import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction';
import { activateLiquidityFor3rdPartyRewards } from '../services/activateLiqFor3rdPartyRewardsService';
import { useApi } from '../../../../services';
import { useAccounts } from '../../../account';
import { useConfig } from 'wagmi';

export const useActivateLiqudityFor3rdPartyRewardsMutation = () => {
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const activateLiqFor3rdPartyRewardsMutation = useMutation(
    ['activate-liquidity-for-3rd-party-rewards'],
    activateLiquidityFor3rdPartyRewards(api, selectedAccount?.address, config, transactionStore),
  );

  return { activateLiqFor3rdPartyRewardsMutation };
};
