import { useAccount } from 'wagmi';
import { isEmpty } from 'lodash-es';
import cls from 'classnames';
import {
  ClassName,
  NotificationKey,
  TestId,
  WalletAccount,
  useModalStore,
  useUserStore,
} from 'core';
import { toast } from 'react-hot-toast';
import { Container, Text } from 'ui';

import { NoAccountsLayout } from '../noAccounts/NoAccountsLayout';
import { ConnectedAccountCard } from '../../../components/ConnectedAccountCard/ConnectedAccountCard';
import { AccountItem } from './item/AccountItem';
import { LockedWalletLayout } from '../../Locked/LockedWalletLayout';

interface AccountListLayoutProps extends TestId, ClassName {}

export function AccountListLayout({
  'data-testid': testId = 'accountList',
  className,
}: AccountListLayoutProps) {
  const { addresses, connector } = useAccount();
  const { setSelectedAccount, selectedAccount, isWalletUnlocked } = useUserStore();
  const { closeAccountSettings } = useModalStore();

  const anotherAccounts = addresses?.filter((address) => address !== selectedAccount?.address);

  const handleAddressSelect = (account: WalletAccount) => {
    if (connector) {
      setSelectedAccount(account);
    }
    closeAccountSettings();
    toast(NotificationKey.WALLET_CONNECTED);
  };

  if (!isEmpty(addresses)) {
    return (
      <Container
        column
        data-testid={testId}
        className={cls('w-full', !anotherAccounts && 'pb-4', className)}
        alignItems="stretch"
      >
        {selectedAccount?.address && (
          <>
            <Text
              type="title-4"
              className="ml-4 mb-4"
              id="wallet.modal.account.connected.title"
              color="secondary"
            />
            <ConnectedAccountCard className="mb-6 mx-4" />
          </>
        )}
        {anotherAccounts && !isEmpty(anotherAccounts) && (
          <>
            <Text
              type="title-4"
              className="ml-4 mb-4"
              id={`wallet.modal.account.${selectedAccount?.address ? 'change' : 'select'}.title`}
              color="secondary"
            />
            <Container
              column
              className="mr-2 overflow-y-auto max-h-[calc(80vh_-_285px)] overflow-x-visible pb-4"
            >
              {anotherAccounts.map((account) => (
                <AccountItem
                  data-testid={`${testId}-item`}
                  key={account}
                  data={{
                    address: account,
                    type: null,
                  }}
                  onClick={handleAddressSelect}
                />
              ))}
            </Container>
          </>
        )}
      </Container>
    );
  }

  return (
    <Container fullWidth alignItems="center" className="mb-[75px] mt-[30px]">
      {isWalletUnlocked ? <NoAccountsLayout /> : <LockedWalletLayout />}
    </Container>
  );
}
