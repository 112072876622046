import { useQuery } from '@tanstack/react-query';
import { DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { fetchRollupTokenAllowance } from '../services/rollupTokenAllowanceService';
import { useRolldownContract } from '../../../contract/useRolldownContract';
import { useTokenContract } from '../../contract';

export const useRollupTokenAllowanceQuery = (
  chainId: QueryOptional<string>,
  userAddress: QueryOptional<string>,
  tokenAddress: QueryOptional<string>,
) => {
  const rolldown = useRolldownContract(chainId);

  const {
    tokenContractMetadataQuery: { data: tokenMetadata },
  } = useTokenContract(chainId, tokenAddress, userAddress);

  const token = tokenMetadata?.[0];

  const rollupTokenAllowanceQuery = useQuery(
    ['rollup-token-allowance', userAddress, token?.source.address],
    fetchRollupTokenAllowance(rolldown.address, userAddress, token),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!token && !token.isNative,
      refetchInterval: 3000,
    },
  );

  return {
    rollupTokenAllowanceQuery,
  };
};
