import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../../transaction';
import { QueryOptional, useApi } from '../../../../../services';
import { executeDelegationRequest } from '../services/executeDelegationRequestService';
import { useAccounts } from '../../../../account';
import { useConfig } from 'wagmi';

export const useExecuteDelegationRequestMutation = (collatorAddress: QueryOptional<string>) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { transactionStore } = useTransaction();
  const config = useConfig();

  const executeDelegationRequestMutation = useMutation(
    ['executeDelegationRequest'],
    executeDelegationRequest(
      api,
      selectedAccount?.address,
      collatorAddress,
      config,
      transactionStore,
    ),
  );

  return { executeDelegationRequestMutation };
};
