import { BN } from '@polkadot/util';
import { WalletAccount, useApi } from '../../../../services';
import {
  getWithdrawalExtrinsic,
  getWithdrawalFee,
  submitWithdrawal,
} from '../services/withdrawalService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { transformWithdrawalFee } from '../transformers/withdrawalTransformer';
import { toBN } from 'gasp-sdk';
import { useTransactionStore } from '../../../transaction';
import { StashTokensData } from '../../../token';
import { StashChannel } from '../../../../services';
import { UseWithdrawalsProps } from '../useWithdrawals';
import { useAdaptiveFee } from '../../../fee/adaptive';
import { useConfig } from 'wagmi';

interface WithdrawalQueryProps extends UseWithdrawalsProps {
  tokens: StashTokensData | undefined;
  channels: StashChannel[] | undefined;
  selectedAccount: WalletAccount | null;
}

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
};

export const useWithdrawalQuery = ({
  amount,
  assetId,
  channelId,
  channels,
  tokens,
  selectedAccount,
  destinationAccount,
}: WithdrawalQueryProps) => {
  const api = useApi();
  const transactionStore = useTransactionStore();
  const config = useConfig();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();

  const channel = channels?.find((channel) => channel.channelId === channelId) ?? null;
  const stashToken = tokens?.xcmTokens?.find((token) => token.tokenId === assetId) ?? null;

  const amountBN = amount && stashToken ? toBN(amount, parseInt(stashToken.decimals)) : null;

  const withdrawalExtrinsicQuery = useQuery(
    [
      'withdrawal-extrinsic',
      destinationAccount?.address,
      amountBN?.toString() ?? null,
      stashToken,
      channel,
    ],
    getWithdrawalExtrinsic(api, destinationAccount),
    {
      enabled: !!(
        selectedAccount?.address &&
        stashToken &&
        amountBN &&
        amountBN.gt(new BN(0)) &&
        channel
      ),
      ...queryOptions,
    },
  );

  const withdrawalFee = useQuery(
    ['withdrawal-fee', destinationAccount?.address, withdrawalExtrinsicQuery.dataUpdatedAt],
    getWithdrawalFee(withdrawalExtrinsicQuery.data, selectedAccount),
    {
      select: transformWithdrawalFee({
        channel,
        asset: stashToken,
        tokens: tokens?.allTokens ?? null,
        getAdaptiveFee,
      }),
      enabled:
        !!withdrawalExtrinsicQuery.data &&
        !!amountBN &&
        amountBN.gt(new BN(0)) &&
        !!destinationAccount?.address &&
        isAdaptiveFeeReady,
      ...queryOptions,
    },
  );

  const submitWithdrawalMutation = useMutation({
    mutationKey: ['withdrawal-submit'],
    mutationFn: submitWithdrawal(api, transactionStore, config),
  });

  return {
    withdrawalExtrinsicQuery,
    withdrawalFee,
    submitWithdrawalMutation,
  };
};
