import {
  depositExtrinsic,
  getPaymentInfo,
  XcmTransferValidationService,
  XcmOperation,
  StashToken,
  StashChannel,
  WalletAccount,
  AnyMangataError,
} from '../../../../services';
import { ApiPromise } from '@polkadot/api';
import { TransactionStore, ExtrinsicTx, TxType } from '../../../transaction';
import { ISubmittableResult } from '@polkadot/types/types';
import { QueryFunctionContext } from '@tanstack/react-query';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { transformToAsset } from '../../../token';
import { Config } from 'wagmi';

type getDepositExtrinsicQueryKey = [
  string,
  string | null,
  string | null,
  StashToken | null,
  StashChannel | undefined,
];
type GetDepositFeeQueryKey = [string, string | null, number | undefined];
interface SubmitDepositProps {
  originAccount: WalletAccount;
  destinationAccount: WalletAccount;
  api: ApiPromise | null;
  asset: StashToken;
  amount: string;
  channel: StashChannel;
  extrinsic?: SubmittableExtrinsic<'promise', ISubmittableResult> | null;
  onDone?: () => void;
}

export const getDepositExtrinsic =
  (api: ApiPromise | null) =>
  async ({ queryKey }: QueryFunctionContext<getDepositExtrinsicQueryKey>) => {
    const [, address, amount, asset, channel] = queryKey;

    if (!api || !address || !amount || !asset || !channel) {
      return null;
    }

    return depositExtrinsic({ address, amount, asset, channel, api });
  };

export const getDepositFee =
  (extrinsic?: SubmittableExtrinsic<'promise'> | null) =>
  async ({ queryKey }: QueryFunctionContext<GetDepositFeeQueryKey>) => {
    const [, address] = queryKey;

    if (!extrinsic || !address) {
      return null;
    }

    return getPaymentInfo(extrinsic, address);
  };

export const submitDeposit =
  (
    mangataApi: ApiPromise | null,
    transactionStore: TransactionStore,
    config: Config | null | undefined,
  ) =>
  async ({ amount, originAccount, extrinsic, api, asset, channel, onDone }: SubmitDepositProps) => {
    if (!api || !mangataApi || !extrinsic || !config) {
      return false;
    }
    const originAddress = originAccount.address;

    const tx = new ExtrinsicTx(api, transactionStore, config, originAddress)
      .create(TxType.Deposit)
      .setOptions({ showExplorerLink: false, useDefaultSigner: true, doneOnTrigger: true, onDone })
      .setMetadata({ tokens: [{ ...transformToAsset(asset), amount }] })
      .setTx(extrinsic)
      .build();

    await tx.send();

    try {
      await new XcmTransferValidationService(
        api,
        mangataApi,
        originAddress,
        asset,
        channel,
        XcmOperation.Deposit,
      ).verify();

      tx.done();
    } catch (e) {
      const error = e as AnyMangataError;
      tx.doneWithError({ name: error.message });
    }
  };
