import { useAccounts } from '../../../account';
import { useTransaction } from '../../../transaction';
import { useApi } from '../../../../services';
import { useMutation } from '@tanstack/react-query';
import { claim3rdPartyRewards } from '../services/3rdPartyRewardsService';
import { useConfig } from 'wagmi';

export const useClaim3rdPartyRewardsMutation = () => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const { transactionStore } = useTransaction();

  const claim3rdPartyRewardsMutation = useMutation(
    ['claim-3rd-party-rewards'],
    claim3rdPartyRewards(api, selectedAccount?.address, config, transactionStore),
  );

  return {
    claim3rdPartyRewardsMutation,
  };
};
