import { ClassName, TestId, TrackingTxStatus, useAccounts, useTxTrackingStore } from 'core';
import { Container } from 'ui';
import cls from 'classnames';
import { TxActivityWidgetItem } from './components/TxActivityWidgetItem';

export interface TxActivityWidgetProps extends ClassName, TestId {}

export const TxActivityWidget = ({ 'data-testid': testId, className }: TxActivityWidgetProps) => {
  const { transactions } = useTxTrackingStore();
  const { selectedAccount } = useAccounts();

  const pendingTransactions = transactions.filter(
    ({ status, account }) =>
      account === selectedAccount?.address && status === TrackingTxStatus.Pending,
  );

  return (
    pendingTransactions.length > 0 && (
      <div className={cls('p-4 w-full', className)} data-testid={testId}>
        <Container fullWidth column className="p-2 bg-input rounded-lg">
          {pendingTransactions.map(({ type, amount, asset, timestamp, hash }) => (
            <TxActivityWidgetItem
              key={hash}
              type={type}
              amount={amount}
              asset={asset}
              timestamp={timestamp}
            />
          ))}
        </Container>
      </div>
    )
  );
};
