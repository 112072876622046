import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../../transaction';
import { useApi } from '../../../../../services';
import { useAccounts } from '../../../../account';
import { submitScheduleBondMore } from '../services/ScheduleDelegatorBondMoreService';
import { useConfig } from 'wagmi';

export const useScheduleBondMoreMutation = () => {
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const scheduleBondMoreMutation = useMutation(
    ['scheduleDelegatorBondMore'],
    submitScheduleBondMore(api, selectedAccount?.address, config, transactionStore),
  );
  return { scheduleBondMoreMutation };
};
