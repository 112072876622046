import { ApiPromise } from '@polkadot/api';
import { TransactionStore, ExtrinsicTx, TxType } from '../../../transaction';
import {
  ActivateLiquidityFor3rdPartyRewardsReserveSource,
  QueryOptional,
  ReserveSourceType,
} from '../../../../services';
import { BN } from '@polkadot/util';
import { ReserveSourceForTx } from '../../../balance';
import { Config } from 'wagmi';

interface ActivateLiquidityFor3rdPartyRewardsParams {
  id: string;
  rewardTokenId: string;
  amount: BN;
  symbol: string;
  balanceSources: ReserveSourceForTx<ActivateLiquidityFor3rdPartyRewardsReserveSource>;
}

export const buildBalanceFromPayload = (source: ReserveSourceType) => {
  switch (source) {
    case ReserveSourceType.NativeRewardsLiquidity:
      return ReserveSourceType.NativeRewardsLiquidity;
    case ReserveSourceType.UnspentReserves:
    case ReserveSourceType.AvailableBalance:
    case ReserveSourceType.StakedUnactivatedReserves:
      return {
        [ReserveSourceType.ActivateKind]: source,
      };
    default:
      return source;
  }
};

const createActivateLiqFor3rdPartyRewardsTx = (
  api: ApiPromise,
  id: string,
  rewardTokenId: string,
  sources: ReserveSourceForTx<ActivateLiquidityFor3rdPartyRewardsReserveSource>,
) => {
  if (!sources.hasAvailable) {
    return;
  }

  if (sources.isBatch) {
    const txs = sources.available.map(([type, amount]) => {
      return api.tx.proofOfStake.activateLiquidityFor3rdpartyRewards(
        id,
        amount.toString(),
        rewardTokenId,
        buildBalanceFromPayload(type),
      );
    });

    return api.tx.utility.batchAll(txs);
  }

  return api.tx.proofOfStake.activateLiquidityFor3rdpartyRewards(
    id,
    sources.totalAvailableBalance,
    rewardTokenId,
    buildBalanceFromPayload(sources.firstAvailableSource),
  );
};

export const activateLiquidityFor3rdPartyRewards =
  (
    api: ApiPromise | null,
    address: string | undefined,
    config: QueryOptional<Config>,
    transactionStore: TransactionStore,
  ) =>
  async ({ id, rewardTokenId, balanceSources }: ActivateLiquidityFor3rdPartyRewardsParams) => {
    if (!api || !address || !config || !balanceSources.hasAvailable) {
      return null;
    }

    const extrinsic = createActivateLiqFor3rdPartyRewardsTx(api, id, rewardTokenId, balanceSources);
    if (!extrinsic) {
      return null;
    }

    return new ExtrinsicTx(api, transactionStore, config, address)
      .create(TxType.ActivateLPFor3rdPartyRewards)
      .setTx(extrinsic)
      .build()
      .send();
  };
