import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction';
import { activateLiquidity } from '../services/ActivateLiquidityMutationService';
import { useApi } from '../../../../services';
import { useAccounts } from '../../../account';
import { useConfig } from 'wagmi';

export const useActivateLiqudityMutation = () => {
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const activateLiquidityMutation = useMutation(
    ['activate-liquidity'],
    activateLiquidity(api, selectedAccount?.address, config, transactionStore),
  );
  return { activateLiquidityMutation };
};
