import { useUserStore } from '../user/useUserStore';
import { useAccount, useDisconnect } from 'wagmi';

export const useAccounts = () => {
  const { selectedAccount, selectedWallet, setSelectedAccount, disconnectWallet } = useUserStore();
  const { isConnected, connector, addresses } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const connectorName = connector?.name || null;

  // TODO: remove accountsQuery&accountsByType together with the rest of legacy code
  const accountsQuery = {
    data: addresses?.map((address) => ({ address, type: null })),
  };
  const accountsByType = null;

  const handleDisconnectWallet = async () => {
    await disconnectAsync();
    disconnectWallet();
  };

  return {
    isConnected,
    accountsQuery,
    setSelectedAccount,
    selectedAccount,
    selectedWallet,
    connectorName,
    disconnectWallet: handleDisconnectWallet,
    accountsByType,
  };
};
