import { Container } from '../Container/Container';
import cls from 'classnames';

export const PulsingDots = () => {
  const BASE_DOT_CLASS =
    'bg-black w-[3px] h-[3px] rounded-full absolute origin-center animate-scaleWithRipple';
  return (
    <Container className="relative w-[12px] h-[12px] [&>div:origin-center]">
      <div className={cls(BASE_DOT_CLASS, 'left-[calc(50%-1.5px)] top-0')}></div>
      <div
        className={cls(BASE_DOT_CLASS, 'right-0 top-[calc(50%-1.5px)]')}
        style={{ animationDelay: '300ms' }}
      ></div>
      <div
        className={cls(BASE_DOT_CLASS, 'left-[calc(50%-1.5px)] bottom-0')}
        style={{ animationDelay: '600ms' }}
      ></div>
      <div
        className={cls(BASE_DOT_CLASS, 'left-0 top-[calc(50%-1.5px)]')}
        style={{ animationDelay: '900ms' }}
      ></div>
    </Container>
  );
};
