import { useAccount } from 'wagmi';
import { useUserStore } from '../user/useUserStore';

export function useWallet() {
  const { setSelectedWallet } = useUserStore();
  const { isConnected, connector } = useAccount();

  const selectedWallet = isConnected ? connector?.name : null;
  const selectedWalletQuery = {
    data: null,
  };

  return {
    selectedWalletQuery,
    selectedWallet,
    setSelectedWallet,
  };
}
