import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../../transaction';
import { useApi } from '../../../../../services';
import { useAccounts } from '../../../../account';
import { submitScheduleBondLess } from '../services/ScheduleDelegatorBondLessService';
import { useConfig } from 'wagmi';

export const useScheduleBondLessMutation = () => {
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const scheduleBondLessMutation = useMutation(
    ['scheduleDelegatorBondLess'],
    submitScheduleBondLess(api, selectedAccount?.address, config, transactionStore),
  );
  return { scheduleBondLessMutation };
};
