import { Decimal } from 'decimal.js';
import { Contract, Web3 } from 'web3';
import { rolldownAbi } from '../../../ContractAbis.json';
import { QueryFunctionContext } from '@tanstack/react-query';
import { TransactionStore, TxType, transformToAsset } from '../../../..';
import { RollupChainTx } from '../../../../transaction';
import { QueryOptional } from '../../../../../services';
import { RollupToken, RollupStashChain } from '../../../stash/RollupStash';
import { Hex } from 'viem';
import { Config } from 'wagmi';

export type RollupApproveQueryParams = [
  string,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
];

export interface RollupApproveMutationParams {
  userAddress: QueryOptional<string>;
  token: RollupToken;
  amount: string;
  gasAmount: QueryOptional<string>;
  chain: QueryOptional<RollupStashChain>;
}

export interface RollupApproveFee {
  gasAmount: string;
  ethAmount: string;
}

const web3 = new Web3();

export const getRollupApproveFee =
  (token: QueryOptional<RollupToken>, rolldownContractAddress: QueryOptional<string>) =>
  async ({
    queryKey,
  }: QueryFunctionContext<RollupApproveQueryParams>): Promise<RollupApproveFee | null> => {
    const [, userAddress, amount, gasPrice] = queryKey;

    if (
      !amount ||
      !userAddress ||
      !token ||
      !rolldownContractAddress ||
      !gasPrice ||
      token.isNative
    ) {
      return null;
    }

    const approveMethod = token.contract.methods.approve(
      rolldownContractAddress,
      new Decimal(`${amount}e${token.decimals}`).toFixed(),
    );

    try {
      const gasAmount = (await approveMethod.estimateGas({ from: userAddress })).toString() || '0';
      const totalInWei = new Decimal(gasAmount).mul(gasPrice).toString();

      return {
        gasAmount,
        ethAmount: web3.utils.fromWei(totalInWei, 'ether'),
      };
    } catch (error) {
      throw new Error('Fee fetching failed');
    }
  };

export const submitRollupApprove =
  (
    transactionStore: TransactionStore,
    config: QueryOptional<Config>,
    rolldownContract: QueryOptional<Contract<typeof rolldownAbi>>,
    gasPrice: QueryOptional<string | null>,
  ) =>
  async ({ token, userAddress, amount, gasAmount, chain }: RollupApproveMutationParams) => {
    if (!config || !gasPrice || !userAddress || !gasAmount || !rolldownContract || !chain) {
      return;
    }

    const approveMethod = token.contract.methods.approve(
      rolldownContract.options.address,
      new Decimal(`${amount}e${token.decimals}`).toFixed(),
    );

    if (!token.contract.options.address) {
      return;
    }

    const txParams = {
      account: userAddress as Hex,
      to: token.contract.options.address as Hex,
      data: approveMethod.encodeABI() as Hex,
      gasPrice: BigInt(gasPrice),
      gas: BigInt(gasAmount),
    };

    const tx = new RollupChainTx(config, transactionStore)
      .create(TxType.ApproveContract)
      .setMetadata({ tokens: [{ ...transformToAsset(token), amount }] })
      .setOptions({ isVisible: false, waitForReceipt: true })
      .setTx(txParams, chain.explorerUrl)
      .build();

    const txHash = await tx.send();

    return {
      txHash,
      onDone: tx.done,
      isSuccess: !!txHash,
    };
  };
