import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../transaction';
import { useApi } from '../../../../services';
import { deactivateLiquidity } from '../services/DeactivateLiquidityMutationService';
import { useAccounts } from '../../../account';
import { useConfig } from 'wagmi';

export const useDeactivateLiqudityMutation = () => {
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const deactivateLiquidityMutation = useMutation(
    ['deactivate-liquidity'],
    deactivateLiquidity(api, selectedAccount?.address, config, transactionStore),
  );
  return { deactivateLiquidityMutation };
};
