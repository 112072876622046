import { useMutation } from '@tanstack/react-query';
import { useInvestedPools } from '../../../pool';
import { useApi } from '../../../../services';
import { useAccounts } from '../../../account';
import { useTransaction } from '../../../transaction';
import { use3rdPartyRewards } from '../../3rdParty';
import { useNativeRewards } from '../../native';
import { claimAllPoolRewards, claimAllRewards } from '../services/rewardsService';
import { useConfig } from 'wagmi';

export const useRewardsMutation = () => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const { transactionStore } = useTransaction();
  const {
    thirdPartyRewardsAmountQuery: { data: thirdPartyRewardsAmount },
  } = use3rdPartyRewards();
  const { rewardQueriesByLpId } = useNativeRewards();

  const { data: investedPools } = useInvestedPools();

  const claimAllPoolRewardsMutation = useMutation(
    ['claim-pool-rewards'],
    claimAllPoolRewards(
      api,
      selectedAccount?.address,
      config,
      transactionStore,
      thirdPartyRewardsAmount,
      rewardQueriesByLpId,
    ),
  );

  const claimAllRewardsMutation = useMutation(
    ['claim-all-rewards'],
    claimAllRewards(
      api,
      selectedAccount?.address,
      config,
      transactionStore,
      thirdPartyRewardsAmount,
      rewardQueriesByLpId,
      investedPools?.baseList,
    ),
  );

  return {
    claimAllPoolRewardsMutation,
    claimAllRewardsMutation,
  };
};
