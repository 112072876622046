import { DEFAULT_QUERY_OPTIONS, QueryOptional, useWeb3 } from '../../../../../services';
import { useCoingeckoContractInfoQuery } from '../../../../coingecko';
import { useRollupChainsQuery } from '../../../stash/query/useRollupChainsQuery';
import { useRollupTokensQuery } from '../../list/query/useRollupTokensQuery';
import { getTokenContractMetadata } from '../services/tokenContractService';
import { transformTokenContractMetadata } from '../transformers/tokenContractTransformer';
import { useQuery } from '@tanstack/react-query';
import { isNull } from 'lodash-es';

export const useTokenContractQuery = (
  chainId: QueryOptional<string>,
  tokenAddress: QueryOptional<string>,
  userAddress: QueryOptional<string>,
) => {
  const {
    rollupChainsQuery: { data: chains },
  } = useRollupChainsQuery();

  const {
    rollupTokensQuery: { data: tokens },
  } = useRollupTokensQuery();

  const web3 = useWeb3();
  const chainWeb3 = (chainId && web3?.[chainId]) || null;
  const chainKey = chains?.find((c) => c.chainId === chainId)?.key;

  const { contractInfoQuery } = useCoingeckoContractInfoQuery(chainKey, tokenAddress);

  const tokenContractMetadataQuery = useQuery(
    ['token-contract-metadata', chainId, tokenAddress, userAddress],
    getTokenContractMetadata(chainWeb3, userAddress, tokenAddress),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !isNull(chainWeb3) && !!tokenAddress && contractInfoQuery.isFetched,
      select: transformTokenContractMetadata(chainKey, chainId, tokens, contractInfoQuery.data),
    },
  );

  return {
    tokenContractMetadataQuery,
  };
};
