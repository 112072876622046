import { Config } from 'wagmi';
import { TransactionStore } from './private/useTransactionStore';
import { TxStatus } from './Transaction';
import { TxBase } from './TxBase';
import { type SendTransactionVariables } from 'wagmi/query';
import { sendTransaction } from '@wagmi/core';
import { waitForTransactionReceipt } from 'viem/actions';

export class RollupChainTx extends TxBase {
  private txParams?: SendTransactionVariables<Config, number>;
  private chainExplorerBaseUrl?: string;

  constructor(private config: Config, store: TransactionStore) {
    super(store);
    this.done = this.done.bind(this);
  }

  setTx(params: SendTransactionVariables<Config, number>, explorerBaseUrl?: string) {
    this.chainExplorerBaseUrl = explorerBaseUrl;
    this.txParams = params;
    return this;
  }

  async send() {
    try {
      const result = await this.execute();

      if (!this.options.doneOnTrigger) {
        this.store.set(this.id, { status: TxStatus.Success });
      }

      return result;
    } catch (e) {
      this.handleError(e);
    } finally {
      if (!this.options.doneOnTrigger) {
        this.scheduleHide();
      }
    }
  }

  private async execute() {
    if (this.txParams && this.config) {
      const txHash = await sendTransaction(this.config, this.txParams);

      if (txHash) {
        this.store.set(this.id, { status: TxStatus.Pending });
        this.setExplorerLink(txHash);

        if (!this.options.doneOnTrigger && this.options.waitForReceipt) {
          const receipt = await waitForTransactionReceipt(this.config.getClient(), {
            hash: txHash,
          });

          return receipt.transactionHash;
        }
      }

      return txHash;
    }

    return null;
  }

  private setExplorerLink = (txHash: string) => {
    if (this.chainExplorerBaseUrl) {
      this.store.set(this.id, {
        explorerUrl: `${this.chainExplorerBaseUrl}/tx/${txHash}`,
      });
    }
  };
}
