import { ExtrinsicSubscriptionData, MangataGenericEvent, MangataInstance, toBN } from 'gasp-sdk';
import { some } from 'lodash-es';
import { Decimal } from 'decimal.js';
import {
  TransactionErrorEvent,
  TransactionStore,
  TxStatus,
  TxAsset,
  TxType,
  SdkTx,
} from '../../../transaction';
import { AddLiquidityTxSucess } from '../../Pool';
import { AnyMangataError, QueryOptional, WalletAccount } from '../../../../services';
import { EnvConfig } from '../../../../envConfig';
import { Config } from 'wagmi';

export type AddLiqudityProps = {
  firstToken: TxAsset;
  secondToken: TxAsset;
  selectedAccount: WalletAccount;
  secondAssetBalance: string | null;
  firstAssetBalance: string | null;
  onDone?: () => void;
};

export const SLIPPAGE_KOEF = 0.01;

const isAddLiquiditySuccessful = (txEvents: MangataGenericEvent[]) => {
  switch (true) {
    case some(txEvents, { method: AddLiquidityTxSucess.PoolCreated }):
    case some(txEvents, { method: AddLiquidityTxSucess.LiquidityMinted }):
      return true;
  }

  return false;
};

const getError = (txEvents: MangataGenericEvent[]) => {
  const failedExtEvent = txEvents.find(
    (event) => event.method === TransactionErrorEvent.ExtrinsicFailed,
  );

  return {
    name: failedExtEvent?.error?.documentation.join(' ') || 'Unknown error',
  };
};

const getAmountWithSlippage = (amount: string) => new Decimal(amount).mul(1 + SLIPPAGE_KOEF);

export const submitAddLiqudity =
  (
    sdk: MangataInstance | null,
    transactionStore: TransactionStore,
    poolIds: string[] | undefined,
    wagmiConfig: QueryOptional<Config>,
  ) =>
  async ({
    selectedAccount,
    firstToken,
    secondToken,
    secondAssetBalance,
    firstAssetBalance,
    onDone,
  }: AddLiqudityProps) => {
    if (!selectedAccount || !sdk || !wagmiConfig) {
      return false;
    }

    const isPoolAlreadyCreated = poolIds?.includes(`${firstToken.id}-${secondToken.id}`);
    const transaction = new SdkTx(transactionStore)
      .create(isPoolAlreadyCreated ? TxType.AddLiquidity : TxType.CreatePool)
      .setOptions({ onDone, doneOnTrigger: true });

    const statusCallback = (txStatus: ExtrinsicSubscriptionData) => {
      if (txStatus.status.isReady) {
        transactionStore.set(transaction.id, { status: TxStatus.Pending });
      }

      if (txStatus.txHash && EnvConfig.EXPLORER_URL) {
        transaction.setExplorerUrl(
          `${EnvConfig.EXPLORER_URL}/extrinsic/${txStatus.txHash.toHex()}`,
        );
      }
    };

    const extrinsicStatusCallback = (txEvents: MangataGenericEvent[]) => {
      if (isAddLiquiditySuccessful(txEvents)) {
        transaction.done();
      } else {
        transaction.doneWithError(getError(txEvents));
      }
    };

    const commonProps = {
      firstTokenId: firstToken.id,
      secondTokenId: secondToken.id,
      firstTokenAmount: toBN(firstToken.amount, firstToken.decimals),
      account: selectedAccount.address,
      txOptions: {
        wagmiConfig,
        statusCallback,
        extrinsicStatus: extrinsicStatusCallback,
      },
    };

    try {
      if (isPoolAlreadyCreated && firstAssetBalance && secondAssetBalance) {
        const secondAssetBalanceDec = new Decimal(secondAssetBalance);
        const secondAmountWSlip = getAmountWithSlippage(secondToken.amount);
        const firstAmountWSlip = getAmountWithSlippage(firstToken.amount);
        const isSecondBalanceInsuff = secondAmountWSlip.gt(secondAssetBalance);
        const isFirstBalanceInsuff = firstAmountWSlip.gt(firstAssetBalance);

        if (isSecondBalanceInsuff) {
          const tx = () =>
            sdk.xyk.mintLiquidity({
              ...commonProps,
              firstTokenId: secondToken.id,
              secondTokenId: firstToken.id,
              firstTokenAmount: toBN(
                (isFirstBalanceInsuff
                  ? secondAssetBalanceDec.mul(1 - SLIPPAGE_KOEF)
                  : secondAssetBalanceDec
                )
                  .toDP(secondToken.decimals, 0)
                  .toFixed(),
                secondToken.decimals,
              ),
              expectedSecondTokenAmount: toBN(
                (isFirstBalanceInsuff ? new Decimal(firstAssetBalance) : firstAmountWSlip)
                  .toDP(firstToken.decimals, 0)
                  .toFixed(),
                firstToken.decimals,
              ),
            });
          transaction.setTx(tx);
        } else {
          const tx = () =>
            sdk.xyk.mintLiquidity({
              ...commonProps,
              expectedSecondTokenAmount: toBN(
                secondAmountWSlip.toDP(secondToken.decimals, 0).toFixed(),
                secondToken.decimals,
              ),
            });

          transaction.setTx(tx);
        }
      } else {
        const tx = () =>
          sdk.xyk.createPool({
            ...commonProps,
            secondTokenAmount: toBN(secondToken.amount, secondToken.decimals),
          });
        transaction.setTx(tx);
      }

      await transaction.build().send();

      return true;
    } catch (e) {
      const error = e as AnyMangataError;
      const errMessage = (error.data ?? error.message).toString();

      transactionStore.set(transaction.id, { status: TxStatus.Error, error: { name: errMessage } });
    }

    return false;
  };
