import { QueryFunctionContext } from '@tanstack/react-query';
import {
  Asset,
  ExtrinsicTx,
  QueryOptional,
  RollupStashChain,
  TrackingTx,
  TrackingTxStatus,
  TransactionStore,
  TxType,
} from '../../../../..';
import { ApiPromise } from '@polkadot/api';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN, toBN } from 'gasp-sdk';
import { MangataTypesAssetsL1Asset } from '@polkadot/types/lookup';
import { Config } from 'wagmi';

export type RollupWithdrawalQueryParams = [
  string,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<string>,
  QueryOptional<MangataTypesAssetsL1Asset['type']>,
];

export interface RollupWithdrawalMutationParams {
  userAddress: QueryOptional<string>;
  tokenAddress: QueryOptional<string>;
  destinationAddress: QueryOptional<string>;
  destinationChain: QueryOptional<RollupStashChain>;
  asset: QueryOptional<Asset>;
  amount: string;
  trackingMeta: TrackingTx['trackingMeta'];
}

export const getRollupWithdrawalFee =
  (api: QueryOptional<ApiPromise>) =>
  async ({ queryKey }: QueryFunctionContext<RollupWithdrawalQueryParams>) => {
    const [, tokenAddress, userAddress, amount, chainKey] = queryKey;

    if (!tokenAddress || !amount || !userAddress || !api || !chainKey) {
      return null;
    }

    try {
      const extrinsic = api.tx.rolldown.withdraw(
        chainKey,
        userAddress,
        tokenAddress,
        toBN(amount).toString(),
      );

      const feeInfo = await extrinsic.paymentInfo(userAddress);

      return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    } catch (error) {
      throw new Error(`Fee fetching failed. ${error}`);
    }
  };

export const submitRollupWithdrawal =
  (
    api: QueryOptional<ApiPromise>,
    transactionStore: TransactionStore,
    config: QueryOptional<Config>,
    trackTx: (tx: TrackingTx) => void,
  ) =>
  async ({
    asset,
    userAddress,
    amount,
    destinationAddress,
    tokenAddress,
    destinationChain,
    trackingMeta,
  }: RollupWithdrawalMutationParams) => {
    if (
      !api ||
      !config ||
      !userAddress ||
      !amount ||
      !asset ||
      !destinationAddress ||
      !tokenAddress ||
      !destinationChain
    ) {
      return;
    }

    const extrinsic = api.tx.rolldown.withdraw(
      destinationChain.key,
      destinationAddress,
      tokenAddress,
      toBN(amount, asset.decimals).toString(),
    );

    const tx = new ExtrinsicTx(api, transactionStore, config, userAddress)
      .create(TxType.RollupWithdrawal)
      .setMetadata({ tokens: [{ ...asset, amount }] })
      .setTx(extrinsic)
      .setOptions({ isVisible: false })
      .build();

    const res = await tx.send();

    if (res) {
      trackTx({
        hash: extrinsic.hash.toString(),
        status: TrackingTxStatus.Pending,
        type: TxType.RollupWithdrawal,
        amount: amount,
        asset: asset,
        account: userAddress,
        timestamp: new Date().toISOString(),
        trackingMeta,
      });
    }

    return !!res;
  };
