import { ApiPromise } from '@polkadot/api';
import { PoolWithRatio } from '../../../pool';
import { AssetRegistryAssetMetadata, QueryOptional } from '../../../../services';
import { isFirstPoolTokenLeaderType } from '../../bucket/TokenBucket';
import { Asset, AssetOrigin } from '../../Token';
import { RollupToken } from '../../../rollup/stash/RollupStash';

interface GetLiquidityAssetsParams {
  pools: QueryOptional<PoolWithRatio[]>;
  assets: QueryOptional<AssetRegistryAssetMetadata[]>;
  tradeableTokenIds: QueryOptional<string[]>;
  isFirstPoolTokenLeader: isFirstPoolTokenLeaderType;
}

interface GetNativeAssetsParams {
  metadata: QueryOptional<AssetRegistryAssetMetadata[]>;
  rollupTokens: QueryOptional<RollupToken[] | null>;
  tradeableTokenIds: QueryOptional<string[]>;
}

export const transformLPAsset = <T extends AssetRegistryAssetMetadata | Asset>(
  asset: T,
  pool: PoolWithRatio | undefined,
  isFirstPoolTokenLeader: isFirstPoolTokenLeaderType,
) => {
  if (!pool) {
    return null;
  }

  const poolSymbols = isFirstPoolTokenLeader(pool) ? pool.symbols.reverse() : pool.symbols;
  const tokenSymbol = poolSymbols.join('-');

  return {
    ...asset,
    name: `${tokenSymbol} Liquidity Token`,
    symbol: tokenSymbol,
  };
};

export const getAssetMetadata = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  const assets = await api.query.assetRegistry.metadata.entries();

  if (assets) {
    return assets;
  }

  return null;
};

export const getLiquidityAssets =
  ({ pools, assets, tradeableTokenIds, isFirstPoolTokenLeader }: GetLiquidityAssetsParams) =>
  () => {
    if (!pools || !assets || !tradeableTokenIds) {
      return null;
    }

    const poolMap = new Map<string, PoolWithRatio>(
      pools.map((pool) => [pool.liquidityTokenId, pool]),
    );

    const liquidityAssets = assets
      .filter((asset) => poolMap.has(asset.id) && tradeableTokenIds.includes(asset.id))
      .map((asset) => transformLPAsset(asset, poolMap.get(asset.id), isFirstPoolTokenLeader));

    return liquidityAssets.filter(Boolean) as AssetRegistryAssetMetadata[];
  };

export const getNativeAssets =
  ({ metadata, rollupTokens, tradeableTokenIds }: GetNativeAssetsParams) =>
  () => {
    if (!metadata || !tradeableTokenIds) {
      return null;
    }

    const nativeAssets = metadata.reduce<AssetRegistryAssetMetadata[]>((acc, asset) => {
      const rollupToken = rollupTokens?.find((token) => token.l2Id === asset.id);

      if (
        asset?.name.includes('Liquidity') ||
        (!rollupToken && asset?.name.includes('L1Asset')) ||
        !tradeableTokenIds?.includes(asset.id)
      ) {
        return acc;
      }

      acc.push(
        rollupToken
          ? {
              ...asset,
              name: rollupToken.name,
              symbol: rollupToken.symbol,
              decimals: rollupToken.decimals,
              source: rollupToken.source,
              origin: rollupToken.origin || AssetOrigin.Native,
            }
          : asset,
      );

      return acc;
    }, []);

    return nativeAssets;
  };
