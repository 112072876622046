import { Asset, ClassName, RollupToken, TestId, TxType } from 'core';
import { Container, formatAmount, PulsingDots, Text, TokenIcon } from 'ui';
import cls from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { ReactComponent as ArrowIcon } from 'icons/arrow-right.svg';

export interface TxActivityWidgetItemProps extends ClassName, TestId {
  type: TxType.RollupDeposit | TxType.RollupWithdrawal;
  amount: string;
  asset: Asset | RollupToken;
  timestamp: string;
}

export const TxActivityWidgetItem = ({
  amount,
  type,
  timestamp,
  asset,
  'data-testid': testId,
  className,
}: TxActivityWidgetItemProps) => {
  const isDeposit = type === TxType.RollupDeposit;

  return (
    <Container
      alignItems="center"
      justifyContent="space-between"
      className={cls('px-2 py-2 w-full', className)}
      data-testid={testId}
    >
      <Container alignItems="center">
        <Container className="relative">
          <TokenIcon token={asset.symbol} size="m" />
          <Container className="absolute -bottom-1 -right-[2px] z-10 p-[3px] bg-input rounded-full">
            <ArrowIcon
              className={cls(
                'w-[10px] h-auto stroke-icon-highlight',
                isDeposit ? 'rotate-45' : '-rotate-45',
              )}
            />
          </Container>
        </Container>
        <Container column className="ml-2">
          <Text
            type="title-5"
            id={`txActivityWidget.${type}.title`}
            values={{
              amount: formatAmount(amount, {
                precision: 3,
                minThreshold: '0.001',
                nonZeroPrecision: true,
              }),
              symbol: asset.symbol,
            }}
          />
          <Text
            color="secondary"
            type="tiny"
            id="txActivityWidget.time"
            values={{ time: formatDistanceToNow(new Date(timestamp)) }}
          />
        </Container>
      </Container>

      <Container alignItems="center" className="rounded-full bg-attention px-2 py-1">
        <PulsingDots />
        <Text type="tiny" id="common.processing" className="ml-1.5" />
      </Container>
    </Container>
  );
};
