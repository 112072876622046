import { ApiPromise } from '@polkadot/api';
import { StorageKey } from '@polkadot/types';
import { u32, u64 } from '@polkadot/types/primitive';
import { PalletProofOfStakeSchedule } from '@polkadot/types/lookup';
import { QueryFunctionContext } from '@tanstack/react-query';
import { QueryOptional } from '../../../../services';
import { TransactionStore, ExtrinsicTx, TxType } from '../../../transaction';
import { Asset } from '../../../token';
import { Codec, ITuple } from '@polkadot/types/types';
import { Option } from '@polkadot/types/codec';
import { Config } from 'wagmi';

export type Get3rdPartyRewardsInfoQueryKey = Readonly<
  [
    queryKey: string,
    address: string | undefined,
    liquidityTokenId: QueryOptional<string>,
    rewardTokenId: QueryOptional<string>,
    txCount: number,
  ]
>;

export type Get3rdPartyRewardsAmountQueryKey = Readonly<
  [queryKey: string, address: string | undefined, finalizedTxCount: number]
>;

interface Claim3rdPartyRewardsParams {
  lpId: string;
  asset: Asset;
  amount: string;
}

export const get3rdPartyRewardsSchedulesList =
  (api: ApiPromise | null) =>
  async (): Promise<
    [StorageKey<[u64]>, Option<ITuple<[PalletProofOfStakeSchedule, Option<u64>]>>][] | null
  > => {
    if (!api) {
      return null;
    }

    return api.query.proofOfStake.rewardsSchedulesList.entries();
  };

export const get3rdPartyTokens =
  (api: ApiPromise | null) => async (): Promise<[StorageKey<[u32, u32]>, Codec][] | null> => {
    if (!api) {
      return null;
    }

    return api.query.proofOfStake.rewardTokensPerPool.entries();
  };

export const getAll3rdPartyRewardsInfo = (api: ApiPromise | null) => async () => {
  if (!api) {
    return null;
  }

  return api.query.proofOfStake.rewardsInfoForScheduleRewards.entries();
};

export const getAll3rdPartyRewardsAmount =
  (api: ApiPromise | null) =>
  async ({ queryKey: [, address] }: QueryFunctionContext<Get3rdPartyRewardsAmountQueryKey>) => {
    if (!api || !address) {
      return null;
    }

    return api.rpc.pos.calculate_3rdparty_rewards_all(address);
  };

export const claim3rdPartyRewards =
  (
    api: ApiPromise | null,
    address: string | undefined,
    config: QueryOptional<Config>,
    transactionStore: TransactionStore,
  ) =>
  async ({ lpId, asset, amount }: Claim3rdPartyRewardsParams) => {
    if (!api || !address || !config) {
      return null;
    }
    const tx = api.tx.proofOfStake.claim3rdpartyRewards(lpId, asset.id);
    return new ExtrinsicTx(api, transactionStore, config, address)
      .create(TxType.Claim3rdParty)
      .setMetadata({ amount, symbol: asset.symbol })
      .setTx(tx)
      .build()
      .send();
  };
