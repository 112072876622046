import { TestId, Asset } from 'core';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { Decimal } from 'decimal.js';

import { Container, FiatValue, FormatAmount, IconButton, SearchBar, Skeleton, Text } from '../../';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { filterTokenListBySearch } from './listHelpers';
import { TokenRow } from '../TokenRow/TokenRow';

export type TokenListBalances = Map<string, string>;

export interface TokenListProps extends TestId {
  onSettingsOpen?(): void;
  onClose?(): void;
  isClosable?: boolean;
  onTokenSelect?(token: Asset): void;
  settingsVisible?: boolean;
  tokens: Asset[];
  withHeader?: boolean;
  balances?: TokenListBalances;
}

export const TokenList = ({
  'data-testid': testId = 'tokenList',
  onTokenSelect,
  onSettingsOpen,
  settingsVisible = true,
  isClosable = true,
  withHeader = true,
  onClose,
  tokens,
  balances,
}: TokenListProps) => {
  const intl = useIntl();
  const [search, setSearch] = useState<string>('');
  const _filteredTokens = filterTokenListBySearch(tokens, search);

  const list = _filteredTokens?.sort(sortAssetByBalance(balances));

  const renderRightElement = (token: Asset) => {
    const freeBalance =
      (token.source && balances?.get(token.source.address)) ?? balances?.get(token.id);

    if (freeBalance === undefined) {
      return (
        <Container column alignItems="end">
          <Skeleton width="60px" height="10px" />
          <Skeleton width="40px" height="10px" className="mt-1" />
        </Container>
      );
    }

    return (
      <Container column alignItems="end">
        <Text>
          <FormatAmount
            value={freeBalance || '0'}
            options={{ precision: 3 }}
            data-testid="token-amount"
          />{' '}
          {token.symbol}
        </Text>
        <Text color="secondary">
          <FiatValue id={token.id} amount={freeBalance} />
        </Text>
      </Container>
    );
  };
  const renderItems = () => {
    if (tokens.length < 1) {
      return (
        <Text
          color="secondary"
          className="self-center self-middle justify-self-center"
          id="token.select.empty"
        />
      );
    }

    return list?.map((token) => (
      <TokenRow
        key={`${token.id}-${token.symbol}`}
        iconSize="m"
        iconType="row"
        className="w-full mb-2"
        data-testid={`${testId}-item`}
        token={token}
        rightElement={renderRightElement(token)}
        onClick={() => onTokenSelect?.(token)}
      />
    ));
  };

  return (
    <Container
      className="w-full h-full flex-1 overflow-hidden"
      column
      alignItems="stretch"
      data-testid={testId}
    >
      <Container className="w-full mb-5 px-2" column alignItems="stretch">
        {withHeader && (
          <Container alignItems="center" justifyContent="space-between" className="w-full mb-3">
            <Text type="title-1" id="token.select.modal.title" />
            <Container alignItems="center">
              {settingsVisible && (
                <IconButton
                  data-testid={`${testId}-settings`}
                  className="mr-5"
                  Icon={SettingsIcon}
                  onClick={onSettingsOpen}
                />
              )}
              {isClosable && (
                <IconButton data-testid={`${testId}-close`} Icon={CloseIcon} onClick={onClose} />
              )}
            </Container>
          </Container>
        )}
        <SearchBar
          placeholder={intl.formatMessage({ id: 'token.select.modal.search' })}
          className="w-full"
          onChange={setSearch}
          data-testid={`${testId}-search`}
        />
      </Container>
      <Container
        alignItems="center"
        className="h-full overflow-auto max-h-[calc(80vh_-_100px)]"
        column
      >
        {renderItems()}
      </Container>
    </Container>
  );
};

function sortAssetByBalance(balances: TokenListBalances | undefined) {
  return (a: Asset, b: Asset) => {
    const aBalance = (a.source && balances?.get(a.source.address)) ?? balances?.get(a.id);
    const bBalance = (b.source && balances?.get(b.source.address)) ?? balances?.get(b.id);

    if (aBalance === undefined || bBalance === undefined) {
      return 0;
    }

    const aBalanceDecimal = new Decimal(aBalance);
    const bBalanceDecimal = new Decimal(bBalance);

    return bBalanceDecimal.cmp(aBalanceDecimal);
  };
}
