import { TestId, useModalStore, useUserStore } from 'core';
import { Container, Modal, Text, WalletLogo } from 'ui';

import { WalletLoadingLayout } from './loading/WalletLoadingLayout';
import { AccountListLayout } from './list/AccountListLayout';
import { useAccount } from 'wagmi';
import { useEffect } from 'react';

export type SelectAccountModalProps = TestId;

export const SelectAccountModal = ({
  'data-testid': testId = 'selectAccountModal',
}: SelectAccountModalProps) => {
  const { isAccountSettingsOpen, closeAccountSettings } = useModalStore();
  const { isConnected, isDisconnected, address } = useAccount();
  const { selectedWallet, setWalletUnlocked, isWalletUnlocked } = useUserStore();

  useEffect(() => {
    if (isWalletUnlocked && isDisconnected && selectedWallet && !address) {
      setWalletUnlocked(false);
    }

    if (!isWalletUnlocked && isConnected && selectedWallet && address) {
      setWalletUnlocked(true);
    }
  }, [isDisconnected, selectedWallet, address, isWalletUnlocked, isConnected, setWalletUnlocked]);

  const renderLayout = () => {
    switch (true) {
      case isConnected || isDisconnected:
        return <AccountListLayout />;
      default:
        return <WalletLoadingLayout />;
    }
  };

  return (
    selectedWallet && (
      <Modal
        isOpen={isAccountSettingsOpen}
        onClose={closeAccountSettings}
        className="w-[520px] px-4 flex-col pb-0"
        data-testid={testId}
      >
        <Container className="px-4 py-2 mb-6" alignItems="center">
          <WalletLogo className="w-9" wallet={selectedWallet} />
          <Text
            type="title-2"
            className="ml-4"
            id={`wallet.modal.account.${address ? 'connected' : 'connecting'}.header`}
            values={{ wallet: selectedWallet }}
          />
        </Container>
        {renderLayout()}
      </Modal>
    )
  );
};
