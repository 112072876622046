import { BN } from '@polkadot/util';
import { useMutation, UseQueryResult } from '@tanstack/react-query';
import { QueryOptional, useApi } from '../../../../services';
import { useAccounts } from '../../../account';
import { useTransaction } from '../../../transaction';
import { useMangataAsset } from '../../../token';
import { claimAllRewards, claimRewards } from '../service/nativeRewardsService';
import { useConfig } from 'wagmi';

export const useNativeRewardsMutation = (
  rewardQueriesByLpId: Record<string, UseQueryResult<QueryOptional<BN>>>,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { mangataAsset } = useMangataAsset();
  const config = useConfig();
  const { transactionStore } = useTransaction();

  const claimAllRewardsMutation = useMutation(
    ['claim-all-rewards'],
    claimAllRewards(
      api,
      rewardQueriesByLpId,
      selectedAccount?.address,
      config,
      transactionStore,
      mangataAsset,
    ),
  );

  const claimRewardsMutation = useMutation(
    ['claim-rewards'],
    claimRewards(
      api,
      rewardQueriesByLpId,
      selectedAccount?.address,
      config,
      transactionStore,
      mangataAsset,
    ),
  );

  return {
    claimAllRewardsMutation,
    claimRewardsMutation,
  };
};
