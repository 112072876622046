import { useQueries, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import {
  DEFAULT_QUERY_OPTIONS,
  QUERY_REFETCH_INTERVAL,
  QueryOptional,
  useWeb3,
} from '../../../../../services';
import {
  fetchRollupTokenBalances,
  GetRollupTokenBalancesQueryKey,
} from '../services/rollupTokenBalanceService';
import { useRollupTokensQuery } from '../../list/query/useRollupTokensQuery';
import { useRollupChainsQuery } from '../../../stash/query/useRollupChainsQuery';

type RollupTokenBalancesQueryOptions = UseQueryOptions<
  Map<string, string>,
  unknown,
  Map<string, string>,
  GetRollupTokenBalancesQueryKey
>;

export type RollupTokenBalancesQueries = Map<
  string,
  UseQueryResult<Map<string, string> | null, unknown>
>;

export const useRollupTokenBalancesQuery = (
  userAddress: QueryOptional<string>,
  chainId: QueryOptional<string>,
) => {
  const { rollupTokensQuery } = useRollupTokensQuery();
  const web3s = useWeb3();

  const tokens = rollupTokensQuery.data;

  const rollupTokenBalancesQuery = useQuery(
    ['rollup-token-balances', userAddress, chainId],
    fetchRollupTokenBalances(web3s, tokens),
    {
      ...DEFAULT_QUERY_OPTIONS,
      enabled: !!tokens && !!userAddress && !!web3s && !!chainId,
      refetchInterval: QUERY_REFETCH_INTERVAL.externalBalance,
    },
  );

  return {
    rollupTokenBalancesQuery,
  };
};

export const useRollupTokenBalancesChainQueries = (
  userAddress: QueryOptional<string>,
  refetchInterval?: number,
) => {
  const { rollupChainsQuery } = useRollupChainsQuery();
  const { rollupTokensQuery } = useRollupTokensQuery();
  const web3s = useWeb3();

  const tokens = rollupTokensQuery.data;
  const chainIds = rollupChainsQuery.data?.map((chain) => chain.chainId);

  const queries: RollupTokenBalancesQueryOptions[] = chainIds
    ? chainIds.map((chainId) => ({
        ...DEFAULT_QUERY_OPTIONS,
        queryKey: ['rollup-token-balances', userAddress, chainId],
        queryFn: fetchRollupTokenBalances(web3s, tokens),
        enabled: !!tokens && !!userAddress && !!web3s && !!chainId,
        refetchInterval,
      }))
    : [];

  const chainQueries = useQueries({ queries });
  const areAllFetched = chainQueries.every((query) => query.isFetched);

  if (!chainIds) {
    return {
      areAllFetched: false,
      rollupTokenBalancesChainQueries: null,
    };
  }

  return {
    areAllFetched,
    rollupTokenBalancesChainQueries: new Map(chainQueries.map((query, i) => [chainIds[i], query])),
  };
};
