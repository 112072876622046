import { forEach } from 'lodash-es';
import Decimal from 'decimal.js';
import {
  QUERY_REFETCH_INTERVAL,
  TrackingTxStatus,
  TxType,
  useAccounts,
  useInternalBalance,
  useRollupTokenBalancesChainQueries,
  useTxTrackingStore,
} from 'core';
import { useEffect, useRef } from 'react';
import { isBefore, subMinutes } from 'date-fns';
import { deepCompareArrays } from './helpers';

const SUCCESS_TIMEOUT_MINUTES = 15;

export const useTxTracking = () => {
  const { selectedAccount } = useAccounts();
  const { transactions, updateTxStatus, updateRefBalance } = useTxTrackingStore();
  const pendingTxs = transactions.filter((tx) => tx.status === TrackingTxStatus.Pending);

  const isSomeTxPending = pendingTxs.length > 0;
  const { rollupTokenBalancesChainQueries, areAllFetched } = useRollupTokenBalancesChainQueries(
    selectedAccount?.address,
    isSomeTxPending ? QUERY_REFETCH_INTERVAL.externalBalance : undefined,
  );

  const rollupBalances = areAllFetched ? rollupTokenBalancesChainQueries : null;
  const pendingDepositTxs = pendingTxs.filter((tx) => tx.type === TxType.RollupDeposit);
  const { getFreeBalance, isLoading: isInternalBalanceLoading } = useInternalBalance(
    pendingDepositTxs.length > 0
      ? QUERY_REFETCH_INTERVAL.internalBalanceDepositsPending
      : undefined,
  );

  const pendingDepositBalances = pendingDepositTxs.reduce<[string, string][]>((acc, tx) => {
    const balance = getFreeBalance(tx.asset);

    return !isInternalBalanceLoading &&
      tx.asset.id &&
      !acc.some(([id]: string[]) => id === tx.asset.id)
      ? [...acc, [tx.asset.id, balance || '0']]
      : acc;
  }, []);

  const prevPendingDepositBalances = useRef(pendingDepositBalances);

  useEffect(() => {
    if (
      prevPendingDepositBalances.current.length > 0 &&
      pendingDepositBalances.length > 0 &&
      !deepCompareArrays(prevPendingDepositBalances.current, pendingDepositBalances)
    ) {
      const balanceChanges = pendingDepositBalances.map(([id, balance]) => {
        const prevBalance =
          prevPendingDepositBalances.current.find(([prevId]) => prevId === id)?.[1] || 0;

        return [id, new Decimal(balance).sub(prevBalance).toFixed()];
      });

      forEach(balanceChanges, ([id, change]) => {
        const txs = pendingDepositTxs.filter(({ asset }) => asset.id === id);

        if (txs.length > 0) {
          const txsAmoutSum = txs.reduce((acc, tx) => acc.add(tx.amount), new Decimal(0));

          forEach(txs, (tx) => {
            if (new Decimal(tx.amount).eq(change) || new Decimal(txsAmoutSum).eq(change)) {
              updateTxStatus(tx.hash, TrackingTxStatus.Success);
            } else {
              updateRefBalance(
                tx.hash,
                new Decimal(tx.trackingMeta.refBalance).add(change).toFixed(),
              );
            }
          });
        }
      });
    }

    if (pendingDepositBalances.length > 0) {
      prevPendingDepositBalances.current = pendingDepositBalances;
    }
  }, [pendingDepositBalances, pendingDepositTxs, updateRefBalance, updateTxStatus]);

  useEffect(() => {
    forEach(pendingDepositTxs, (tx) => {
      const currentBalance = getFreeBalance(tx.asset);
      const sameAssetTxSum = pendingDepositTxs.reduce(
        (acc, _tx) => (_tx.asset.id === tx.asset.id ? acc.add(tx.amount) : acc),
        new Decimal(0),
      );

      if (
        currentBalance &&
        (new Decimal(tx.trackingMeta.refBalance).add(tx.amount).eq(currentBalance) ||
          new Decimal(tx.trackingMeta.refBalance).add(sameAssetTxSum).eq(currentBalance))
      ) {
        updateTxStatus(tx.hash, TrackingTxStatus.Success);
      }
    });
  }, [pendingDepositTxs, getFreeBalance, updateTxStatus]);

  useEffect(() => {
    if (isSomeTxPending) {
      forEach(pendingTxs, (tx) => {
        if (tx.type === TxType.RollupWithdrawal && tx.trackingMeta.refBalance) {
          const currentBalance =
            tx.asset.source &&
            rollupBalances?.get(tx.asset.source.chainId)?.data?.get(tx.asset.source.address);

          if (
            currentBalance &&
            new Decimal(tx.trackingMeta.refBalance).add(tx.amount).eq(currentBalance)
          ) {
            updateTxStatus(tx.hash, TrackingTxStatus.Success);
          }
        }

        if (isBefore(new Date(tx.timestamp), subMinutes(new Date(), SUCCESS_TIMEOUT_MINUTES))) {
          updateTxStatus(tx.hash, TrackingTxStatus.Success);
        }
      });
    }
  }, [isSomeTxPending, pendingTxs, rollupBalances, updateTxStatus]);
};
