import { create } from 'zustand';
import { SwapData, Asset } from 'core';
import { SwapFormError } from '../useSwapValidation';
import { persist, createJSONStorage } from 'zustand/middleware';
import { pick } from 'lodash-es';
import { SwapInputType } from '../SwapPage';

const SWAP_STORAGE_KEY = 'swap';
const statesToPersist: (keyof SwapUIStore)[] = ['isAutoRoutingEnabled'];

export interface SwapUIStore {
  firstToken: Asset | null;
  secondToken: Asset | null;
  firstAmount: string;
  secondAmount: string;
  routeDetails: SwapData | null;
  formError: SwapFormError | null;
  isChartVisible: boolean;
  isGasless: boolean;
  maxSlippagePerc: number;
  areSettingsOpen: boolean;
  areSwapDetailsOpen: boolean;
  isAutoRoutingEnabled: boolean;
  lastInputChange: SwapInputType | null;
  isTokenLaunchBannervisible: boolean;
  setFirstToken: (token: Asset | null) => void;
  setSecondToken: (token: Asset | null) => void;
  setFirstAmount: (amount: string) => void;
  setSecondAmount: (amount: string) => void;
  setRouteDetails: (details: SwapData | null) => void;
  setLastInputChange: (params: SwapInputType | null) => void;
  setFormError: (error: SwapFormError | null) => void;
  setChartVisible: (isChartVisible: boolean) => void;
  setGasless: (isGasless: boolean) => void;
  setSettingsOpen: (areSettingsOpen: boolean) => void;
  setRoutingEnabled: (isAutoRoutingEnabled: boolean) => void;
  setSwapDetailsOpen: (areSwapDetailsOpen: boolean) => void;
  setIsTokenLaunchBannerVisible: (isTokenLaunchBannervisible: boolean) => void;
  dispose: () => void;
}

export const INITIAL_STATE = {
  firstToken: null,
  secondToken: null,
  firstAmount: '',
  secondAmount: '',
  routeDetails: null,
  formError: null,
  isGasless: false,
  isChartVisible: false,
  maxSlippagePerc: 1,
  areSettingsOpen: false,
  areSwapDetailsOpen: true,
  isAutoRoutingEnabled: true,
  isTokenLaunchBannervisible: false,
  lastInputChange: null,
};

export const useSwapStore = create(
  persist<SwapUIStore>(
    (set) => ({
      ...INITIAL_STATE,
      setFirstToken: (token) => set({ firstToken: token }),
      setSecondToken: (token) => set({ secondToken: token }),
      setFirstAmount: (amount) => set({ firstAmount: amount }),
      setSecondAmount: (amount) => set({ secondAmount: amount }),
      setRouteDetails: (details) => set({ routeDetails: details }),
      setFormError: (error) => set({ formError: error }),
      setGasless: (isGasless) => set({ isGasless }),
      setChartVisible: (isChartVisible) => set({ isChartVisible }),
      setSettingsOpen: (areSettingsOpen) => set({ areSettingsOpen }),
      setRoutingEnabled: (isAutoRoutingEnabled) => set({ isAutoRoutingEnabled }),
      setSwapDetailsOpen: (areSwapDetailsOpen) => set({ areSwapDetailsOpen }),
      setLastInputChange: (lastInputChange) => set({ lastInputChange }),
      setIsTokenLaunchBannerVisible: (isTokenLaunchBannervisible) =>
        set({ isTokenLaunchBannervisible }),
      dispose: () => {
        set({
          firstToken: null,
          secondToken: null,
          firstAmount: '',
          secondAmount: '',
          routeDetails: null,
          formError: null,
          isGasless: false,
          isChartVisible: false,
          areSettingsOpen: false,
        });
      },
    }),
    {
      name: SWAP_STORAGE_KEY,
      partialize: (state) => pick(state, statesToPersist),
      storage: createJSONStorage(() => localStorage),
      version: 1,
    },
  ),
);
